import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import * as d3 from "d3";
import Chart from "chart.js/auto";
import { ChartType } from 'chart.js';
import 'chartjs-adapter-moment';
import annotationPlugin from 'chartjs-plugin-annotation';
import { CommonModule } from "@angular/common";
import { FeatherModule } from "angular-feather";
import { showNotification } from '../../events';
import { FormsModule } from "@angular/forms";
import { firstValueFrom } from "rxjs";
import { PlotsService } from "../../services/plots.service";
import { UtilService } from '../../services/util.service';
import { ChartService } from '../../services/chart.service';
import { NdviDataMultiple, NdviDataSorted, PlotMonitors, PlotEvent } from '../../model/plot';
import { Monitor } from "../../model/assessment";

declare module 'chart.js' {
  interface PluginOptionsByType<TType extends ChartType> {
    customCanvasBackgroundColor?: {
      color?: string
    }
  }
}


Chart.register(annotationPlugin);

@Component({
  standalone: true,
  selector: 'app-admin-charts',
  templateUrl: './admin-charts.component.html',
  imports: [
    CommonModule,
    FeatherModule,
    FormsModule
  ],
  styleUrls: ['./admin-charts.component.scss']
})

export class AdminChartsComponent {

  @ViewChild('canvas') canvas: ElementRef | undefined;

  isLoadingData = false;
  isDataLoaded = false;
  filtersMinimized = false;
  allData: {
    ndviData: NdviDataMultiple,
    lockoutMonitor: Monitor | undefined,
    plotMonitors: PlotMonitors | undefined,
    allEvents: PlotEvent[] | null
  }[] | null = null;
  activeIndex: number = 0;
  dataFilters: any = {
    type: [],
    id: []
  }

  sources: { id: string; label: string }[] = [{
    id: 'SENTINEL',
    label: 'Sentinel'
  }, {
    id: 'PLANET',
    label: 'Planet'
  },
  {
    id: 'lockout',
    label: 'Sperrzeitraum'
  }, {
    id: 'validEvents',
    label: 'Events (gültig)'
  },
  {
    id: 'invalidEvents',
    label: 'Events (ungültig)'
  }];

  chart: Chart | undefined;

  get objectIDsString(): string {
    return this.dataFilters.id.join('; ');
  };
  constructor(private plotsService: PlotsService,
    private util: UtilService,
    private chartsService: ChartService) {

  }


  toggleMinimizeFilters() {
    this.filtersMinimized = !this.filtersMinimized;
  }


  async requestData() {
    this.activeIndex = 0;
    this.isLoadingData = true;
    this.isDataLoaded = false;
    this.allData = null;
    const ndviData = await firstValueFrom(this.plotsService.getNdviDataMultiple(this.dataFilters.id));
    if (ndviData.length > 0) {
      this.allData = [];
      for (const element of ndviData) {
        let plotMonitors: PlotMonitors | undefined = undefined;
        let lockoutMonitor: Monitor | undefined = undefined;
        let allEvents: PlotEvent[] | null = null;
        if (this.dataFilters.type.includes('lockout')) {
          plotMonitors = await firstValueFrom(this.plotsService.getPlotMonitors(element.id));
          lockoutMonitor = await firstValueFrom(this.plotsService.getMonitor('SPERR_BRA'));
        }
        if (this.dataFilters.type.includes('validEvents') || this.dataFilters.type.includes('invalidEvents')) {
          allEvents = await firstValueFrom(this.plotsService.getPlotEvents(element.id));
        }
        
        this.allData!.push({
          ndviData: element,
          plotMonitors: plotMonitors ? plotMonitors : undefined,
          lockoutMonitor: lockoutMonitor ? lockoutMonitor : undefined,
          allEvents: allEvents ? allEvents : null
        });
        console.log(this.allData);
      };
      console.log("create chart", this.allData);
      this.toggleMinimizeFilters();
      this.createChartData(this.allData[this.activeIndex]);
    }

    this.isLoadingData = false;
    this.isDataLoaded = true;
  }


  async createChartData(plotData: {
    ndviData: NdviDataMultiple,
    lockoutMonitor: Monitor | undefined,
    plotMonitors: PlotMonitors | undefined,
    allEvents: PlotEvent[] | null
  }) {
    console.log('createChartData', plotData);
    await this.clearChart();

    const satelliteData: NdviDataSorted[] = [];
    const planetNdvi: { date: Date; median: number }[] = [];
    const sentinelNdvi: { date: Date; median: number }[] = [];
    let events: PlotEvent[] = [];

    for (const measurement of plotData.ndviData.measurementsNdvi) {
      if (measurement.source === 'PLANET' && this.dataFilters.type.includes('PLANET')) {
        planetNdvi.push({ date: new Date(measurement.date), median: measurement.median });
      } else if (measurement.source === 'SENTINEL' && this.dataFilters.type.includes('SENTINEL')) {
        sentinelNdvi.push({ date: new Date(measurement.date), median: measurement.median });
      }
    }

    const interpolatedPlanetNdvi = planetNdvi.length > 0 ? await this.util.interpolateDates(planetNdvi) : [];
    const interpolatedSentinelNdvi = sentinelNdvi.length > 0 ? await this.util.interpolateDates(sentinelNdvi) : [];

    const currentYear = this.getCurrentYear(interpolatedSentinelNdvi, interpolatedPlanetNdvi);
    const allDates = d3.timeDays(
      new Date(currentYear - 1 +'-12-31'),
      new Date(currentYear + '-11-31')
    );

    for (const date of allDates) {
      const interpolatedSentinelValue = this.getInterpolatedValue(date, interpolatedSentinelNdvi);
      const interpolatedPlanetValue = this.getInterpolatedValue(date, interpolatedPlanetNdvi);

      satelliteData.push({
        date,
        ndviSentinel: interpolatedSentinelValue ? interpolatedSentinelValue.median : null,
        ndviPlanet: interpolatedPlanetValue ? interpolatedPlanetValue.median : null
      });
    }

    if (this.dataFilters.type.includes('lockout') && plotData.plotMonitors && plotData.lockoutMonitor) {
      const isLockoutShown = this.findSperrBra(plotData.plotMonitors);

      if (isLockoutShown) {
        plotData.lockoutMonitor.dateStartParsed = this.getDateParsed(plotData.lockoutMonitor.dateStart, currentYear);
        plotData.lockoutMonitor.dateEndParsed = this.getDateParsed(plotData.lockoutMonitor.dateEnd, currentYear);
      }
    }

    if ((this.dataFilters.type.includes('validEvents') || this.dataFilters.type.includes('invalidEvents')) && plotData.allEvents) {
      events = this.getEvents(plotData.allEvents);
    }

    await this.drawChart(satelliteData, sentinelNdvi, planetNdvi, plotData.ndviData.id, plotData.lockoutMonitor, events);
  }

  private getCurrentYear(sentinelNdvi: { date: Date; median: number }[], planetNdvi: { date: Date; median: number }[]) {
    const sentinelDate = sentinelNdvi[Math.floor(sentinelNdvi.length / 2)]?.date;
    const planetDate = planetNdvi[Math.floor(planetNdvi.length / 2)]?.date;
    return sentinelDate ? sentinelDate.getFullYear() : (planetDate ? planetDate.getFullYear() : 2024);
  }

  private getInterpolatedValue(date: Date, interpolatedData: { date: Date; median: number }[]) {
    return interpolatedData.find(data => data.date.getTime() === date.getTime());
  }

  private getDateParsed(date: string, currentYear: number) {
    const [day, month] = date.split('.');
    return new Date(currentYear, Number(month) - 1, Number(day));
  }

  private getEvents(allEvents: PlotEvent[]) {
    const events = [];
    if (this.dataFilters.type.includes('validEvents')) {
      events.push(...allEvents.filter(event => event.valid));
    } 
    if (this.dataFilters.type.includes('invalidEvents')) {
      events.push(...allEvents.filter(event => !event.valid));
    }
    return events;
  }

  private async clearChart() {
    this.chart?.destroy();
  }



  async drawChart(data: NdviDataSorted[], sentinelOriginal: { date: Date; median: number }[],
    planetOriginal: { date: Date; median: number }[], plotId: number,
    lockout: Monitor | undefined, events: PlotEvent[]) {
      console.log("draw chart", data);
    await this.clearChart();
    if (data.length > 0 && this.canvas) {
      const chartData: { labels: any[], datasets: any[], allAnnotations: any[] } = await this.chartsService.createChart(data, sentinelOriginal,
        planetOriginal, undefined, undefined, lockout, events);


      const labels = chartData.labels;
      const datasets = chartData.datasets;
      const allAnnotations = chartData.allAnnotations;

      const me = this;

      for (var name in allAnnotations) {
        if (name.indexOf('Sentinel NDVI') === 0) {
          allAnnotations[name].display = function() { 
            const dataset = me.chart?.legend?.legendItems?.find((obj: any) => {
              return obj.text === 'Sentinel NDVI';
            });
            return dataset && !dataset.hidden;
            }
        } else if (name.indexOf('Planet NDVI') === 0) {
          allAnnotations[name].display = function() { 
            const dataset = me.chart?.legend?.legendItems?.find((obj: any) => {
              return obj.text === 'Planet NDVI';
            });
            return dataset && !dataset.hidden;
            }
        }
      }


      this.chart = new Chart(this.canvas.nativeElement, {
        type: 'line',
        data: {
          labels,
          datasets
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          animation: false,
          scales: {
            y: {
              title: {
                display: true,
                text: 'NDVI'
              },
              min: 0,
              max: 1.0
            },
            x: {
              title: {
                display: true,
                text: 'Datum'
              },
              min: labels[0],
              max: labels[labels.length - 1],
              type: 'time',
              time: {
                unit: 'month',
                displayFormats: {
                  month: 'D.MM.YY'
                }
              },
              grid: {
                color: "#bababa"
              }
            }
          },
          plugins: {
            title: {
              display: true,
              text: 'ID: ' + plotId.toString()
            },
            customCanvasBackgroundColor: {
              color: 'white',
            },
            annotation: {
              annotations: allAnnotations
            },
            legend: {
              labels: {
                // TODO make it more obvious that they're clickable
              }
            }
          },
        },
        plugins: [{
          id: 'customCanvasBackgroundColor',
          beforeDraw: (chart, args, options) => {
            const { ctx } = chart;
            ctx.save();
            ctx.globalCompositeOperation = 'destination-over';
            ctx.fillStyle = options['color'] || '#99ffff';
            ctx.fillRect(0, 0, chart.width, chart.height);
            ctx.restore();
          }
        }]
      });
      this.chart.update();
    }
  }

  private pointVisible(type: string) {
    const dataset = this.chart?.legend?.legendItems?.find(obj => {
      return obj.text === type;
    });
    return dataset && !dataset.hidden;
  }

  resetDataFilters() {
    this.isDataLoaded = false;
    this.dataFilters = {
      type: [],
      id: []
    }
    this.setObjectIDsString('');
    this.allData = null;
    this.activeIndex = 0;
    this.clearChart();
  }

  setObjectIDsString(objectIDsString: string) {
    if (objectIDsString.match(/[\d\s;]+/)) {
      this.dataFilters.id = objectIDsString.split(';')
        .map(objectID => Number.parseInt(objectID.trim(), 10))
        .filter(objectId => !isNaN(objectId));
    } else if (!objectIDsString.match(/^\s*$/)) {
      showNotification.next({
        title: 'Fehler',
        message: 'Objekt-IDs dürfen nur aus Zahlen bestehen. Bei der Eingabe mehrerer Objekt-IDs müssen diese durch Semikolons voneinander getrennt werden.',
        class: 'is-danger'
      });
    }
  }

  exportChart() {
    if (this.allData && this.allData.length > 0 && this.allData[this.activeIndex]) {
      const plotId = this.allData![this.activeIndex].ndviData!.id;
      const declaredCode = this.allData[this.activeIndex].ndviData!.nCodeDeclared.code ? this.allData[this.activeIndex].ndviData!.nCodeDeclared.code!.toString() : "";
  
      const methods = this.dataFilters.type.join('_')
      const chartDataURL = this.chart!.toBase64Image();
  
      const link = document.createElement('a');
      link.href = chartDataURL;
      link.download = `${plotId.toString()}_${declaredCode}_${methods}.png`;
      link.click();
    }
  }

  findSperrBra(plotMonitors: PlotMonitors) {
    if (plotMonitors && plotMonitors.finalResults.length > 0) {
      return plotMonitors.finalResults.find(item => item.monitor === 'SPERR_BRA');
    } else {
      return false;
    }
  }

  moveChart(direction: number) {
    console.log("moveChart", direction);
    if (this.allData && this.allData.length > 0) {
      this.activeIndex = this.activeIndex + direction;
      if (this.activeIndex < 0) {
        this.activeIndex = this.allData.length - 1;
      } else if (this.activeIndex >= this.allData.length) {
        this.activeIndex = 0;
      }
      this.createChartData(this.allData[this.activeIndex]);
    }
  }


  queryButtonDisabled() {
    return this.dataFilters.id.length === 0 || (this.dataFilters.type.indexOf('PLANET') < 0 && this.dataFilters.type.indexOf('SENTINEL') < 0);
  }
}



// 6523526; 6498187; 6523470; 6652080
