import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map, Observable, switchMap} from 'rxjs';
import {UtilService} from './util.service';
import {amsUrl, showNotification} from '../events';

/**
 * Arguments for a GraphQL query
 *
 * @property {string} field - A field to query
 * @property {string[]} attributes - The field's attributes to query
 * @property {string} filter - (optional) Filter part of the query
 * @property {string} alias - (optional) Alias part of the query
 * @property {string} operatorNames - (deprecated/unused) Specific operator names to filter results against
 */
export interface GraphqlQueryArgs {
  field: string;
  attributes?: string[];
  filter?: string,
  alias?: string,
  operatorNames?: string,
}

/**
 * Arguments for a GraphQL mutation
 *
 * @property {string} field - Field to mutate
 * @property {string[]} input - Mutation input
 */
export interface GraphqlMutationArgs {
  field: string;
  input: any;
  returns?: boolean;
}

/**
 * Http options that contain headers needed in every request
 */
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class GraphqlService {

  constructor(private http: HttpClient,
    private util: UtilService,
  ) {
  }

  /**
   * Creates and executes a GraphQL query from given GraphqlQueryArgs.
   *
   * @param args - The GraphqlQueryArgs to create the query from
   * @return An Observable of any type, depending on the query
   */
  private query(...args: GraphqlQueryArgs[]): Observable<any> {
    let query = 'query {';
    args.forEach((arg, index) => {
      if (arg.alias) {
        query += `${arg.alias}: `
      }
      query += arg.field;
      if (arg.filter) {
        query += `(filter: ${arg.filter})`;
      }
      if (arg.attributes) {
        query += ` {${arg.attributes.join(', ')}}`;
        if (index < args.length - 1) {
          query += ',';
        }
      }
    });
    query += '}';

    return amsUrl.pipe(
      switchMap((url: string) => this.http.post(url + '/graphql', JSON.stringify({query}), httpOptions))
    );
  }

  /**
   * Creates and executes a GraphQL mutation from given GraphqlMutationArgs.
   *
   * @param args - The GraphqlMutationArgs to create the mutation from
   * @return An Observable of any type, depending on the mutation
   */
  private mutate(args: GraphqlMutationArgs): Observable<any> {
    let query = `mutation {
    ${args.field}(input: ${this.util.stringifyGraphQLEnums(args.input) })`;
    if (args.returns) { query += `{ ${Object.keys(args.input).join(', ')} }` }
    query += `}`;

    return amsUrl.pipe(
      switchMap((url: string) => this.http.post(url + '/graphql', JSON.stringify({query}), httpOptions))
    )
  }

  /**
   * Executes a GraphQL mutation with error handling.
   *
   * @param {GraphqlMutationArgs[]} args - The arguments for the mutation
   * @return {Observable<any>} The result of the mutation, or null if an error occurred
   */
  mutation(args: GraphqlMutationArgs): Observable<any> {
    return this.mutate(args).pipe(
      map(response => {
        console.log('* Mutation response', response);
        let errorMessages = response.errors?.map((error: any) => error.hasOwnProperty('message') ? error.message : error);
        if (errorMessages) {
          console.error(errorMessages);
          showNotification.next({title: 'Error', class: 'is-error', message: `Error changing ${args.field}: ${errorMessages}`});
          return null;
        }
        return response.data
      })
    );
  }

  /**
   * Creates and executes a GraphQL query for a single field from given GraphqlQueryArgs.
   *
   * @param args - The GraphqlQueryArgs to create the query from
   * @return An Observable of any type, depending on the query
   */
  queryField(args: GraphqlQueryArgs): Observable<any> {
    return this.query(args).pipe(
      map(response => {
        // console.log('test response 1', response);
        let errorMessages = response.errors?.map((error: any) => error.hasOwnProperty('message') ? error.message : error);
        if (errorMessages) {
          const attributesList = args.attributes?.map(str => str?.split(' ')[0]).join(', ');
          console.error(errorMessages);
          showNotification.next({title: 'Error', class: 'is-error', message: `Error querying ${attributesList}: ${errorMessages}`});
          return null;
        }
        return response.data[args.field.split(' ')[0]]
      })
    );
  }

  queryMultipleFields(args: GraphqlQueryArgs[]): Observable<any> {
    return this.query(...args).pipe(
      map(response => {
        // console.log('test response 2', response);
        let errorMessages = response.errors?.map((error: any) => error.hasOwnProperty('message') ? error.message : error)[0];
        if (errorMessages) {
          console.error(errorMessages);
          const attributesList = args[0].field.split(' ')[0];
          showNotification.next({title: 'Error', class: 'is-error', message: `Error querying ${attributesList}: ${errorMessages}`});
          return null;
        }
        return response.data
      })
    );
  }


  // temp(args: GraphqlQueryArgs): Observable<any> {
  //   return this.http.get<any>('assets/json/statisticsByUser-1691073069576.json').pipe(
  //     map(response => response.data[args.field.split(' ')[0]])
  //   )
  // }
}
