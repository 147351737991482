import { Injectable } from '@angular/core';
import { NdviDataSorted, PlotEvent, Coherence } from '../model/plot';
import { Monitor } from "../model/assessment";

@Injectable({
  providedIn: 'root'
})
export class ChartService {

  constructor() { }

  async createChart(data: NdviDataSorted[], sentinelOriginal: { date: Date; median: number }[],
    planetOriginal: { date: Date; median: number }[], orbits: string[] | undefined, coherences: Coherence[] | undefined, lockout: Monitor | undefined, events: PlotEvent[]) {

    let datasets: any[] = [];
    let labels: any[] = [];
    let datesLabels: any[] = [];
    let allAnnotations: any = {};

    if (data.length > 0) {
      labels = data.map(dataSet => dataSet.date.getTime());
      datesLabels = data.map(dataSet => dataSet.date);

      if (data.filter(item => item.ndviSentinel !== null).length > 0) {
        datasets = datasets.concat({
          label: 'Sentinel NDVI',
          data: data.map(dataSet => dataSet.ndviSentinel),
          fill: false,
          borderColor: '#1e44d0',
          tension: 0.1,
          pointRadius: 0.05,
          borderWidth: 2,
        });

      }
      if (data.filter(item => item.ndviPlanet !== null).length > 0) {
        datasets = datasets.concat({
          label: 'Planet NDVI',
          data: data.map(dataSet => dataSet.ndviPlanet),
          fill: false,
          borderColor: '#d16a00',
          tension: 0.1,
          pointRadius: 0.05,
          borderWidth: 2,
        });
      }

      let intensity = 50;
      if (orbits) {
        orbits.forEach(orbit => {
          datasets.push({
            label: `COH ${orbit}`,
            data: data.map(dataSet => {
              const coherence = dataSet.coherences?.find(entry => entry.orbit === orbit && entry.polarisation === 'VH');
              return coherence?.median;
            }),
            fill: false,
            borderColor: `#${intensity.toString(16)}${intensity.toString(16)}${intensity.toString(16)}`,
            tension: 0.1,
            pointRadius: 0.05,
            borderWidth: 2,
            borderDash: [10, 4],
          });
          intensity += 50;
          // datasets.push({
          //   label: `COH ${orbit} VV`,
          //   data: this.data.map(dataSet => {
          //     const coherence = dataSet.coherences.find(entry => entry.orbit === orbit && entry.polarisation === 'VV');
          //     return coherence?.median;
          //   }),
          //   fill: false,
          //   borderColor: `#${intensity.toString(16)}${intensity.toString(16)}${intensity.toString(16)}`,
          //   tension: 0.1,
          //   pointRadius: 0.05,
          //   borderWidth: 2,
          //   borderDash: [10, 4],
          // });
          // intensity += 30;
        });

        if (coherences) {
          orbits.forEach((orbit: string) => {
            const vhCoherences = coherences.find(coherenceCategory => coherenceCategory.orbit === orbit && coherenceCategory.polarisation === 'VH')?.values;
            //const vvCoherences = this.coherences.find(coherenceCategory => coherenceCategory.orbit === orbit && coherenceCategory.polarisation === 'VV')?.values;
    
            if (vhCoherences && vhCoherences.length > 0) {
              for (const [i, item] of vhCoherences.entries()) {
                if (item.median !== null) {
                  allAnnotations[`COH ${orbit} ` + i.toString] = {
                    drawTime: 'afterDraw',
                    type: 'point',
                    xValue: item.date.getTime(),
                    yValue: item.median,
                    backgroundColor: `#${intensity.toString(16)}${intensity.toString(16)}${intensity.toString(16)}`,
                    borderColor: `#${intensity.toString(16)}${intensity.toString(16)}${intensity.toString(16)}`,
                    radius: 2
                  }
                }
              }
            }
            intensity += 50;
            // if (vvCoherences && vvCoherences.length > 0) {
            //   for (const [i, item] of vvCoherences.entries()) {
            //     if (item.median !== null) {
            //       allAnnotations[`COH ${orbit} VV ` + i] = {
            //         drawTime: 'afterDraw',
            //         type: 'point',
            //         xValue: item.date.getTime(),
            //         yValue: item.median,
            //         backgroundColor: `#${intensity.toString(16)}${intensity.toString(16)}${intensity.toString(16)}`,
            //         borderColor: `#${intensity.toString(16)}${intensity.toString(16)}${intensity.toString(16)}`,
            //         radius: 2,
            //         display: () => this.pointVisible('Coherence VV')
            //       }
            //     }
            //   }
            // }
            // intensity += 30;
          });
        }
 
      }

      // add Sperrzeitraum
      if (lockout?.dateEndParsed && lockout?.dateStartParsed) {
        allAnnotations['lockout'] = {
          drawTime: 'beforeDraw',
          type: 'box',
          xMin: lockout.dateStartParsed.getTime(),
          xMax: lockout.dateEndParsed.getTime(),
          yMin: 0.0,
          yMax: 1.0,
          backgroundColor: '#e5e5e5',
          borderColor: '#e5e5e5',
        }
      }

      // add arbitrary lines on 15. of the month because irregular grid doesn't work
      for (const [i, label] of datesLabels.entries()) {
        if (label.getDate() === 15) {
          allAnnotations['grid' + i] = {
            type: 'line',
            xMin: label.getTime(),
            xMax: label.getTime(),
            borderColor: '#e0e0e0',
            borderWidth: 1,
            drawTime: "beforeDatasetsDraw"
          };
        }
      }

      // add event lines
      if (events.length > 0) {
        for (const [i, item] of events.entries()) {
          if (item.date) {
            const color = item.valid ? '#bd384f' : '#ababab';
            const labelColor = item.valid ? '#323232cc' : '#ababab';
            const zValue = item.valid ? 2 : 1;
            allAnnotations['line' + i] = {
              type: 'line',
              xMin: item.date.getTime(),
              xMax: item.date.getTime(),
              borderColor: color,
              borderWidth: 2,
              borderDash: [10, 4],
              label: {
                content: item.date.toLocaleDateString('de-DE', { day: "numeric", month: "numeric" }),
                display: true,
                position: "end",
                rotation: 0,
                backgroundColor: labelColor,
                shadowBlur: 50,
                z: zValue,
                font: {
                  size: 11,
                  weight: 'lighter',
                  lineHeight: 1.0
                }
              }
            };
          }
        }
      }

      // show all real points in the dataset
      if (sentinelOriginal.length > 0) {
        for (const [i, item] of sentinelOriginal.entries()) {
          if (item.median !== null) {
            allAnnotations['Sentinel NDVI ' + i] = {
              drawTime: 'afterDraw',
              type: 'point',
              xValue: item.date.getTime(),
              yValue: item.median,
              backgroundColor: '#1e44d0',
              borderColor: '#1e44d0',
              radius: 2
            }
          }
        }
      }
      if (planetOriginal.length > 0) {
        for (const [i, item] of planetOriginal.entries()) {
          if (item.median !== null) {
            allAnnotations['Planet NDVI ' + i] = {
              drawTime: 'afterDraw',
              type: 'point',
              xValue: item.date.getTime(),
              yValue: item.median,
              backgroundColor: '#d16a00',
              borderColor: '#d16a00',
              radius: 2
            }
          }
        }
      }

    }
    return { labels, datasets, allAnnotations };
  }
}
