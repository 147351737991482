<div class="admin-wrapper">
  <div class="tabs is-boxed">
    <ul>
      <li [ngClass]="{'is-active': activeTab === 'scapi-zuweisung'}">
        <a [routerLink]="['scapi-zuweisung']"><span i18n>sCAPI assignment</span></a>
      </li>
      <li [ngClass]="{'is-active': activeTab === 'sfb-zuweisung'}">
        <a [routerLink]="['sfb-zuweisung']"><span i18n>sFB assignment</span></a>
      </li>
      <li [ngClass]="{'is-active': activeTab === 'statistik'}">
        <a [routerLink]="['statistik']"><span i18n>Statistics</span></a>
      </li>
      <li [ngClass]="{'is-active': activeTab === 'admin-charts'}">
        <a [routerLink]="['admin-charts']"><span i18n>NDVI Plots</span></a>
      </li>
    </ul>
  </div>

  <router-outlet></router-outlet>

</div>
