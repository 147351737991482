import {KeycloakService} from "keycloak-angular";
import {environment} from "../../environments/environment";

export function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: environment.keycloak_url,
        realm: environment.keycloak_realm,
        clientId: environment.keycloak_clientId,
      },
      initOptions: {
        // pkceMethod: 'S256',
        // onLoad: 'login-required',
        // // must match to the configured value in keycloak
        // redirectUri: environment.keycloak_redirectUri,
        // this will solved the error
        // onLoad: 'check-sso',
        // silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
        checkLoginIframe: false
      },
      // shouldAddToken: (request) => {
      //   const { method, url } = request;

      //   const isGetRequest = 'GET' === method.toUpperCase();
      //   const isPutrequest = 'PUT' === method.toUpperCase();
      //   const acceptablePaths = ['/metadata'];
      //   const isAcceptablePathMatch = acceptablePaths.some((path) => url.includes(path));

      //   return !((isGetRequest || isPutrequest) && isAcceptablePathMatch);
      // }
    });
}
