import { Injectable } from '@angular/core';
import * as d3 from "d3";
import {ControlMethod} from '../model/plot';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  controlMethods: ControlMethod[] = ['SENTINEL', 'PLANET', 'CROP_ANALYZER', 'SCAPI', 'SFB']

  /**
   * Helper function to convert objects into GraphQL strings
   * @param obj - The object to convert
   * @return The GraphQL string
   */
  stringifyGraphQL(obj: any) {
    const json = JSON.stringify(obj);
    return json.replace(/"([\w_]+)?":/g, '$1:');
  }

  /**
   * Helper function to convert objects containing control methods as defined in controlMethods into GraphQL strings
   * @param obj - The object to convert
   * @return The GraphQL string
   */
  stringifyGraphQLEnums(obj: any) {
    let json = JSON.stringify(obj);
    this.controlMethods.forEach(method => {
      const regex = new RegExp(`"${method}"`, 'g');
      json = json.replace(regex, method);
    })
    return json.replace(/"([\w_]+)?":/g, '$1:');
  }

  /**
   * Inserts a string into another string at the specified index. Uses array's splice method under the hood.
   *
   * @param originalString - The outer string that should contain the string to insert
   * @param stringToInsert - The inner string that gets inserted
   * @param index - Index of the outer string where to insert the inner string
   * @return The joined string
   */
  insertString(originalString: string, stringToInsert: string, index: number): string {
    const stringArray: string[] = originalString.split('');
    stringArray.splice(index, 0, stringToInsert);
    return stringArray.join('');
  }

  async interpolateDates(data: any[]) {
    // Remove the nulls inside the dataset first
    const noNulls = data.filter(e => e['median'] != null);
    if (noNulls.length > 0) {
      const from = noNulls[0].date;
      const to = noNulls[noNulls.length - 1].date;
      from.setDate(from.getDate() - 3); // to include all the dates
      // const sorted = noNulls.sort((a,b) => new Date(a.date).getTime() - new Date(b.date).getTime());

      const interpolatedData: any[] = [];
      const allDates = d3.timeDays(from, to);

      const xs = noNulls.map(dataSet => dataSet.date.getTime());
      const ys = noNulls.map(dataSet => dataSet['median']);
      const combined: [number, number][] = xs.map((c, i) => {
        return [c, ys[i]]
      });

      const multiLine = d3.scaleLinear()
        .domain(
          combined.map((p) => {
            return p[0];
          })
        )
        .range(
          combined.map((p) => {
            return p[1];
          })
        ).clamp(true);

      allDates.forEach(date => {
        interpolatedData.push({
          date: date,
          median: multiLine(date.getTime())
        });
      });
      return interpolatedData;
    } else {
      return [];
    }

  }
}
