<div class="tabs dashboard-component-tabs is-boxed is-small is-marginless">
  <ul>
    <li class="is-active">
      <a><span i18n>Events</span></a>
    </li>
  </ul>
</div>

<div class="columns-wrapper" *ngIf="lockout && findSperrBra()">
    <div class="columns has-background-grey-lighter">
      <div class="column has-text-weight-bold" i18n>Lockout period</div>
    </div>
    <div class="columns has-background-grey-lighter">
      <div class="column has-text-weight-semibold">
        <app-labeled-field label="" value="{{lockout.dateStart}} - {{lockout.dateEnd}}"></app-labeled-field>
      </div>
    </div>
</div>

<table *ngIf="events; else placeholder"
  class="table is-narrow is-hoverable is-fullwidth table-compact">
  <thead>
    <tr>
      <th (click)="sortBy('type')">
        <div class="cell-wrapper is-flex is-flex-direction-row is-align-items-center is-justify-content-flex-start">
          <ng-container *ngIf="sorting && sorting.column === 'type'">
            <span class="indicator indicator-{{sorting.direction}}"></span>
          </ng-container>
          <ng-container i18n>Event Type</ng-container>
        </div>
      </th>
      <th (click)="sortBy('date')">
        <div class="cell-wrapper is-flex is-flex-direction-row is-align-items-center is-justify-content-flex-end">
          <ng-container *ngIf="sorting && sorting.column === 'date'">
            <span class="indicator indicator-{{sorting.direction}}"></span>
          </ng-container>
          <ng-container i18n>Date</ng-container>
        </div>
      </th>
      <th (click)="sortBy('source')">
        <div class="cell-wrapper is-flex is-flex-direction-row is-align-items-center is-justify-content-flex-start">
          <ng-container *ngIf="sorting && sorting.column === 'source'">
            <span class="indicator indicator-{{sorting.direction}}"></span>
          </ng-container>
          <ng-container i18n>Source</ng-container>
        </div>
      </th>
      <th>
        <div class="cell-wrapper is-flex is-flex-direction-row is-align-items-center is-justify-content-flex-start">
          <ng-container i18n>Valid</ng-container>
        </div>
      </th>
      <!-- <th>
        <div class="cell-wrapper is-flex is-flex-direction-row is-align-items-center is-justify-content-flex-start">
          <ng-container i18n>ResultComment</ng-container>
        </div>
      </th> -->
    </tr>
  </thead>

  <tbody *ngIf="events.length > 0">
    <tr *ngFor="let event of events"
      [ngClass]="{'is-pink': findSperrBra() && checkDate(event.date),
      'grey-background': !event.valid , 'grey-text': !!activeAssessmentStatus }">
      <td class="has-text-left">
        {{event.type}}
      </td>
      <td class="has-text-right">
        {{event.date | date:'short'}}
      </td>
      <td class="has-text-left">
        {{event.source}}
      </td>
      <td class="has-text-centered">
        <input type="checkbox" [(ngModel)]="event.valid" [disabled]="!!activeAssessmentStatus" (ngModelChange)="onValidChange(event)">
      </td>
      <!-- <td class="has-text-left">
        {{event.resultComment}}
      </td> -->
    </tr>
  </tbody>
</table>

<ng-template #placeholder>
  <div class="content-placeholder" i18n>No plot selected.</div>
</ng-template>
