<!-- The application's navbar containing app wide navigation and menus -->
<nav class="navbar" role="navigation" aria-label="main navigation" #navbar>
  <div class="navbar-brand">
    <div class="navbar-item">
      <img ngSrc="../assets/svg/ams_dashboard_long.svg" height="28" width="296" alt="AMS Dashboard Logo" priority>
      <div class="dashboard-version">
        v{{dashboardVersion}}
      </div>
    </div>

    <a role="button" class="navbar-burger" [ngClass]="{'is-active': isHamburgerOpen}" (click)="toggleHamburgerMenu()" aria-label="menu" aria-expanded="false" data-target="startNavigation">
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>
  </div>

  <div id="startNavigation" class="navbar-menu" [ngClass]="{'is-active': isHamburgerOpen}">
    <div class="navbar-start">
      <a class="navbar-item" [ngClass]="{'underline': activeMenuItem === 'scapi'}" (click)="setActiveMenuItem('scapi')">
        SCapi
      </a>

      <a class="navbar-item" [ngClass]="{'underline': activeMenuItem === 'administration'}"
         (click)="setActiveMenuItem('administration')">
        Administration
      </a>
    </div>

    <div class="navbar-end">
      <div *ngIf="states.getActiveState() as activeState" class="navbar-item has-dropdown is-hoverable">
        <a class="navbar-link">
          <span class="icon">

            <picture style="height: 28px">
              <source srcset="assets/img/states/{{activeState.id}}.png, assets/img/states/{{activeState.id}}@2x.png 2x" type="image/png">
              <img src="assets/img/states/{{activeState.id}}.png" alt="{{activeState.name}}">
            </picture>

          </span>
          <span class="margin-left-5">{{activeState.name}}</span>
        </a>

        <div class="navbar-dropdown is-right">
          <a *ngFor="let state of states.getInactiveStates()" class="navbar-item" (click)="resetFeatures(); states.setActiveState(state.id)">
            {{state.name}}
          </a>
        </div>
      </div>

      <div *ngIf="username" class="navbar-item has-dropdown is-hoverable">
        <a class="navbar-link">
          <i-feather name="user"></i-feather>&nbsp;
          {{username}}
        </a>

        <div class="navbar-dropdown is-right">
          <a class="navbar-item" (click)="resetDashboardLayout()">
            <span class="icon">
              <i-feather name="grid"></i-feather>
            </span>
            <span i18n class="margin-left-15">Reset Layout</span>
          </a>
          <hr class="navbar-divider">
          <ng-container *ngIf="user.hasRole('management') || user.hasRole('admin')">
            <a class="navbar-item" (click)="window.open(keyCloakUrl, '_blank')">
            <span class="icon">
              <i-feather name="users"></i-feather>
            </span>
              <span i18n class="margin-left-15">User management</span>
            </a>
            <hr class="navbar-divider">
          </ng-container>
          <a class="navbar-item" (click)="user.logout()">
            <span class="icon">
              <i-feather name="log-out"></i-feather>
            </span>
            <span i18n class="margin-left-15">Log out</span>
          </a>
        </div>
      </div>

      <div *ngIf="timeLeft > 0 && timeLeft < 301 && username" class="navbar-item" title="You will be automatically logged out when inactive." i18n-title="You will be automatically logged out when inactive.">
        <i-feather name="clock"></i-feather>&nbsp;{{timeLeft | time}}
      </div>

      <a class="navbar-item" href="https://eftas.de">
        <img alt="EFTAS Logo" ngSrc="assets/svg/eftas_logo.svg" height="28" width="45">
      </a>
    </div>
  </div>
</nav>

<!-- First level router outlet. Routes defined in app.routes.ts will be injected here -->
<router-outlet *ngIf="states.getActiveState(); else chooseState"></router-outlet>

<!-- State chooser message popover will be shown if no state is selected at startup -->
<ng-template #chooseState>
  <div class="message">
    <div class="message-header">
      <ng-container i18n>Choose active state</ng-container>
    </div>

    <div class="message-body">
      <p class="margin-bottom-15" i18n>Please choose a state from the list</p>
      <div class="select is-fullwidth">
        <select [(ngModel)]="states.selectedStateId">
          <option *ngFor="let state of states.states" [value]="state.id">
            {{state.name}}
          </option>
        </select>
      </div>
    </div>

    <div class="is-flex is-flex-direction-row is-fullwidth is-justify-content-end">
      <div (click)="states.setActiveState(states.selectedStateId)" class="button is-primary is-inverted" i18n>Continue</div>
    </div>
  </div>
</ng-template>

<!-- All notifications created by use of the showNotification Subject will be displayed here -->
<div *ngIf="allNotifications.length > 0" class="message{{allNotifications[0].class ? ' ' + allNotifications[0].class : ''}}">
  <div class="message-header">
    {{allNotifications[0].title}}
    <button class="delete" aria-label="delete" (click)="closeNotification()"></button>
  </div>

  <div class="notification-messages">
    <div *ngFor="let notification of allNotifications" class="message-body"
    style="word-break: break-word;">{{notification.message}}</div>
  </div>
</div>
