import {Component} from '@angular/core';
import {showNotification} from "../../events";
import {SfbAssignment} from "../../model/plot";
import {PlotsService} from "../../services/plots.service";
import {FeatherModule} from "angular-feather";
import {NgxJsonViewerModule} from "ngx-json-viewer";
import {CommonModule} from "@angular/common";

@Component({
  standalone: true,
  selector: 'app-sfb-assignment',
  templateUrl: './sfb-assignment.component.html',
  imports: [
    CommonModule,
    FeatherModule,
    NgxJsonViewerModule
  ],
  styleUrl: './sfb-assignment.component.scss'
})
export class SfbAssignmentComponent {

  assignments: SfbAssignment[] = [];
  selectedSfbFile: File | undefined;
  filtersMinimized = false;
  loading = false;

  constructor(private plotsService: PlotsService) {
  }

  toggleMinimizeFilters() {
    this.filtersMinimized = !this.filtersMinimized;
  }

  onSfbFileSelected(event: any) {
    if (event.target && event.target.files && event.target.files.length >= 1) {
      this.selectedSfbFile = event.target.files[0];
      this.processSfbFile();
    }
  }

  processSfbFile() {
    const reader = new FileReader();

    reader.onload = (event) => {
      if (event.target && event.target.result) {
        const fileContent = event.target.result;
        const lines = (fileContent as string).split('\n');

        lines.forEach((line, index) => {
          if (index >= 1) {
            const fields = line.split(';');
            if (fields.length >= 3) {
              const assignment = {featureId: Number.parseInt(fields[2]), mapIndex: fields[0]};
              let userAssignments = this.assignments.find(assignment => assignment.username === fields[1]);
              if (!userAssignments) {
                userAssignments = {username: fields[1], assignments: []};
                this.assignments.push(userAssignments);
              }
              userAssignments.assignments.push(assignment);
            }
          }
        });
        console.table(this.assignments);
      }
    };

    reader.onerror = (event) => {
      console.error("Error occurred while reading the file");
    };

    if (this.selectedSfbFile) {
      reader.readAsText(this.selectedSfbFile);
    }
  }

  async sendSfbChanges() {
    this.loading = true;
    const combinedResponse = await this.plotsService.sendSfbAssignments(this.assignments);
    this.loading = false;

    let errorMessages = combinedResponse.errors.map((error: any) => error.hasOwnProperty('message') ? error.message : error);
    const additionalErrorMessages = combinedResponse.responses
      .map((response: any) => {
        if (response.errors && response.errors.length && response.errors.length >= 1) {
          return response.errors.map((error: any) => error.hasOwnProperty('message') ? error.message : error)
        }
      })
      .flat(1);
    errorMessages = errorMessages
      .concat(additionalErrorMessages)
      .filter(message => !!message);

    if (errorMessages.length > 0) {
      showNotification.next({
        title: 'Fehler',
        message: `Bei der Bearbeitung der Zuweisungen sind Fehler aufgetreten: ${errorMessages.join(',')}`,
        class: 'is-danger'
      });
    } else {
      showNotification.next({
        title: 'Zuweisung erfolgreich',
        message: `Die sFB-Zuweisungen wurden erfolgreich in das System übernommen.`,
        class: 'is-success'
      });
      this.assignments = [];
      this.selectedSfbFile = undefined;
    }
  }

}
