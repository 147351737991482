import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR} from "@angular/forms";
import {CommonModule} from "@angular/common";

@Component({
  standalone: true,
  selector: 'app-combo-select',
  templateUrl: './combo-select.component.html',
  styleUrls: ['./combo-select.component.scss'],
  imports: [
    CommonModule,
    FormsModule
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ComboSelectComponent),
      multi: true
    }
  ]
})
export class ComboSelectComponent implements ControlValueAccessor {

  @Input() options: any[] = [];

  @Input() labelAttr: string[] = ['label'];
  @Input() valueAttr: string = 'value';

  @Input() disabled: boolean = false;

  private _selectedOptions: any[] = [];
  private onChangeCallback: (_: any) => void = () => {};
  private onTouchedCallback: () => void = () => {};

  get selectedOptions(): any[] {
    return this._selectedOptions;
  }

  set selectedOptions(options: any[]) {
    if (options !== this._selectedOptions) {
      this._selectedOptions = options;
      this.onChangeCallback(options);
    }
  }

  get selectionString(): string {
    if (this.selectedOptions) {
      return this.selectedOptions.join('; ');
    } else {
      return '';
    }
  }

  writeValue(selectedOptions: any[]): void {
    if (selectedOptions !== this.selectedOptions) {
      this.selectedOptions = selectedOptions;
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  getLabel(option: any) {
    let label = '';
    this.labelAttr.forEach((labelAttr, index) => {
      if (index >= 1) {
        label += ': ';
      }
      label += option[labelAttr];
    });
    return label;
  }

  onInputChange(input: string) {
    this.selectedOptions = [];
    if (input.trim() !== '') {
      const parts = input.split(';').map(part => part.trim());
      parts.forEach(part => {
        const selectedOption = this.options.find(option => option[this.labelAttr[0]].toString() === part);
        if (selectedOption) {
          this.selectedOptions.push(selectedOption[this.valueAttr]);
        }
      });
    }
  }
}
