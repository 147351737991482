import {Routes} from '@angular/router';
import {DashboardComponent} from "./components/dashboard/dashboard.component";
import {PageNotFoundComponent} from "./components/page-not-found/page-not-found.component";
import {AuthGuard} from "./guard/auth.guard";
import {administrationRoutes} from "./components/administration/administration.routes";

/**
 * Contains all the app's first level routes. Second level routes and beyond are defined in the respective component's
 * routes definition file, e.g. administration.routes.ts
 */
export const routes: Routes = [{
  path: 'administration',
  children: administrationRoutes,
  canActivate: [AuthGuard],
  data: {roles: ['admin']}
}, {
  path: 'scapi',
  component: DashboardComponent,
  canActivate: [AuthGuard],
  data: {roles: ['scapi']}
}, {
  path: '',
  redirectTo: '/scapi',
  data: {roles: ['scapi']},
  pathMatch: 'full'
}, {
  path: '**',
  component: PageNotFoundComponent
}];
