import {Pipe, PipeTransform} from '@angular/core';
import {Plot} from '../model/plot';

type Priority = { name: string, priority: number };

@Pipe({
  standalone: true,
  name: 'sortMonitors'
})

export class SortMonitorsPipe implements PipeTransform {

  monitorsPriority: Priority[] = [
    {name: 'NUTZ', priority: 0}, // nrw - ALL_NUTZART, other - NUTZ
    {name: 'DGL', priority: 1}, // nrw - BP_MINDEST_DGL, other - LWT_DGL
    {name: 'BRA', priority: 2}, // nrw - BP_MINDEST_BRA_STR, other - MIND_BRA
    {name: 'SPERR_BRA', priority: 3}, // all - SPERR_BRA
    {name: 'NBF', priority: 4}, // nrw - ALL_NBF, other - NBF
    {name: 'KENN', priority: 5} // only NI
  ];

  methodsPriority: Priority[] = [
    {name: 'SENTINEL', priority: 0},
    {name: 'PLANET', priority: 1},
    {name: 'CROP_ANALYZER', priority: 2},
    {name: 'SCAPI', priority: 3},
    {name: 'SFB', priority: 4},
  ]

  compare(attr: string, priorities: Priority[] | null) {
    return (a: any, b: any) => {
      if (priorities) {
        const aPriority: Priority = priorities.filter(obj => (a[attr] === 'SPERR_BRA' && obj.name === a[attr]) || a[attr].includes(obj.name))[0];
        const bPriority: Priority = priorities.filter(obj => (b[attr] === 'SPERR_BRA' && obj.name === b[attr]) || b[attr].includes(obj.name))[0];
        return aPriority.priority - bPriority.priority;
      } else {
        // newer first
        return b[attr].getTime() - a[attr].getTime();
      }
    }
  }

  transform(plot: Plot | null): Plot | null {
    if (plot) {
      if (plot.finalResults.length > 0) {
        plot.finalResults.sort(this.compare('monitor', this.monitorsPriority));
      }
      if (plot.results.length > 0) {
        const sorted: any[] = [];
        this.monitorsPriority.forEach(monObj => {
          const byMonitor = plot.results.filter(a => (a.monitor === 'SPERR_BRA' && monObj.name === a.monitor) || (a.monitor !== 'SPERR_BRA' && a.monitor?.includes(monObj.name)));

          this.methodsPriority.forEach(methObj => {
            const byMethod = byMonitor.filter(a => a.method === methObj.name);
            byMethod.sort(this.compare('date', null)); // group by day or what?

            // Push the objs into resulting array together
            sorted.push.apply(sorted, byMethod);
          });
        });
        plot.results = sorted;
      }
    }
    return plot;
  }

  transformMonitorsArray(array: string[]): string[] {
    const sorted: string[] = [];
    if (array.length > 0) {
      this.monitorsPriority.forEach(monObj => {
        const byMonitor = array.filter(a => (a === 'SPERR_BRA' && monObj.name === a) || (a !== 'SPERR_BRA' && a.includes(monObj.name)));
        sorted.push.apply(sorted, byMonitor);
      });
    }
    return sorted;
  }

}
