import {APP_INITIALIZER, ApplicationConfig, importProvidersFrom, LOCALE_ID, Provider} from '@angular/core';
import {registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import {provideRouter} from '@angular/router';

import {FeatherModule} from 'angular-feather';
import {allIcons} from 'angular-feather/icons';
import {KeycloakAngularModule, KeycloakBearerInterceptor, KeycloakService} from 'keycloak-angular';

import {routes} from './app.routes';
import {initializeKeycloak} from './init/keycloak-init.factory';
import {DndModule} from 'ngx-drag-drop';

registerLocaleData(localeDe);

/**
 * Provider for Keycloak Bearer Interceptor
 */
const KeycloakBearerInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: KeycloakBearerInterceptor,
  multi: true
};

/**
 * Provider for Keycloak Initialization
 */
const KeycloakInitializerProvider: Provider = {
  provide: APP_INITIALIZER,
  useFactory: initializeKeycloak,
  multi: true,
  deps: [KeycloakService]
}

/**
 * Locale provider
 */
const LocaleProvider: Provider = {
  provide: LOCALE_ID,
  useValue: 'de-DE'
}

/**
 * Set of config options available during the application bootstrap operation
 */
export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    provideRouter(routes),
    LocaleProvider,
    KeycloakBearerInterceptorProvider,
    KeycloakInitializerProvider,
    importProvidersFrom(KeycloakAngularModule),
    importProvidersFrom(FeatherModule.pick(allIcons)),
    importProvidersFrom(DndModule)
  ]
};
