<div class="modal is-active">
    <div (click)="cancel()" class="modal-background"></div>
    <swiper-container appSwiper class="swiper" *ngIf="photoData && photoData.length > 0" [config]="swiperOptions">
        <swiper-slide *ngFor="let photo of photoData; let i = index">
            <div class="column has-text-weight-semibold has-text-right">
                <span class="tag is-info is-medium" *ngIf="photo.source === 'SFB'; else faniTag">sFB</span>
                <ng-template #faniTag> 
                  <span class="tag is-link is-medium">FANi</span>
                </ng-template>
              </div>
            <img src="{{ photo.imageId | authImage: false | async }}"
                [ngClass]="{'rotated-clockwise': rotated === 90, 'rotated-counter-clockwise': rotated === 270, 'flipped': rotated === 180}">
        </swiper-slide>
    </swiper-container>


    <div class="buttons has-addons is-centered are-small">
        <button class="button is-black is-small" (click)="rotatePhoto(90)">
            <strong i18n>Rotate</strong>
            <span class="icon is-small">
                <i-feather name="rotate-cw" class="icon-smaller"></i-feather>
            </span>
        </button>

        <button class="button is-black is-small" (click)="rotatePhoto(180)">
            <strong i18n>Flip</strong>
            <span class="icon is-small">
                <i-feather name="refresh-cw" class="icon-smaller"></i-feather>
            </span>
        </button>

        <button class="button is-black is-small" (click)="rotatePhoto(270)">
            <strong i18n>Rotate</strong>
            <span class="icon is-small">
                <i-feather name="rotate-ccw" class="icon-smaller"></i-feather>
            </span>
        </button>

        <button class="button is-black is-small" (click)="rotatePhoto(0)">
            <strong i18n>Reset</strong>
            <span class="icon is-small">
                <i-feather name="stop-circle" class="icon-smaller"></i-feather>
            </span>
        </button>

    </div>
</div>
