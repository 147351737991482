import {Component, EventEmitter, Input, Output} from '@angular/core';
import {PlotShort, PlotShortAttribute, PlotShortAttributeTypes, PlotShortFieldLabels} from "../../model/plot";
import {showNotification} from '../../events';

@Component({
  selector: 'app-plot-list',
  template: ''
})
export abstract class AbstractPlotListComponent {

  _plots: PlotShort[] | null | undefined;

  @Input() set plots(plots: PlotShort[] | null | undefined) {
    this._plots = plots;
    if (plots) {
      this.filteredPlots = plots.map((plot: PlotShort) => plot.clone());
    }
  }

  get plots(): PlotShort[] | null | undefined {
    return this._plots;
  }

  @Output() scrolled: EventEmitter<any> = new EventEmitter();
  @Output() requestSorting: EventEmitter<{direction: 'ASC' | 'DESC'; field: PlotShortAttribute}> = new EventEmitter();
  @Output() requestFilters: EventEmitter<{field: PlotShortAttribute; operation: '<' | '>' | '='; value: string}[]> = new EventEmitter();

  filteredPlots: PlotShort[] | null | undefined;

  columns: PlotShortAttribute[] = ['id', 'eftasId', 'area', 'eftasZone', 'nCodeDeclared'];
  columnLabels = PlotShortFieldLabels;
  @Input() sortableColumns: PlotShortAttribute[] = ['id', 'eftasId', 'area', 'eftasZone', 'nCodeDeclared'];

  filters: { field: PlotShortAttribute; operation: '<' | '>' | '='; value: string }[] = [];
  sorting: { field: PlotShortAttribute; direction: 'ASC' | 'DESC' } = {field: 'id', direction: 'ASC'};

  getColumnType(column: PlotShortAttribute) {
    return PlotShortAttributeTypes[column];
  }

  onSearchInputChange(event: any) {
    const getAllCaptureGroups = (s: string, regex: RegExp) => {
      const matches = [];
      let match;

      while ((match = regex.exec(s)) !== null) {
        const groups = match.slice(1); // Exclude the full match at index 0
        matches.push(groups);
      }

      return matches;
    }

    this.filters = [];
    const input = event.target.value as string;
    const matches = getAllCaptureGroups(input, /([a-zA-ZäöüÄÖÜß-]+)([<>=])([\w.]+)/g);

    matches.forEach(match => {
      const [label, operation, value] = match;
      const entry = Object.entries(PlotShortFieldLabels)
        .find(([key, value]) => value.toLowerCase() === label.toLowerCase());
      if (entry) {
        const field = entry[0] as PlotShortAttribute;
        this.filters.push({field, operation: operation as '<' | '>' | '=', value})
      }
    });

    this.requestFilters.emit(this.filters);
  }

  sortBy(field: PlotShortAttribute) {
    if (this.sortableColumns.includes(field)) {
      const direction = this.sorting && this.sorting.field === field && this.sorting.direction === 'ASC' ? 'DESC' : 'ASC';
      this.sorting = {field, direction};
      this.requestSorting.emit(this.sorting);
    }
  }

  onScroll() {
    this.scrolled.emit();
  }

  showSearchInfo(event: MouseEvent) {
    showNotification.next({
      title: 'Suchfilter',
      message: 'Um einen Suchbegriff über eine Spalte anzuwenden, einfach den Namen der Spalte vorwegstellen mit ' +
        'einem Gleichheitszeichen als Trennzeichen bei Textwerten, also z.B. "Zone=NW67" bzw. "<" oder ">" bei ' +
        'nummerischen Werten, z.B. "Größe<0.5". Dabei wird nicht zwischen Groß- und Kleinschreibung unterschieden, ' +
        'es geht also auch "größe<0.5".'
    });
  }
}
