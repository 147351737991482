import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  standalone: true,
  name: 'time'
})
/**
 * This pipe accepts a numeric value and returns a rounded time in minutes or seconds.
 */
export class TimePipe implements PipeTransform {

  transform(value: number | null, ...args: unknown[]): string {
    if (value) {
      if (value >= 60) {
        return Math.round(value / 60) + 'm';
      } else {
        return value + 's';
      }
    } else {
      return '';
    }

  }

}
