import {Component, Input, OnInit} from '@angular/core';
import {firstValueFrom, map, Observable} from "rxjs";
import {GraphqlService} from "../../services/graphql.service";
import {UtilService} from "../../services/util.service";
import {SortMonitorsPipe} from "../../pipes/sort-monitors.pipe";
import {saveAs} from "file-saver";
import {StatesService} from "../../services/states.service";
import {FeatherModule} from "angular-feather";
import {FormsModule} from "@angular/forms";
import {ComboSelectComponent} from "../combo-select/combo-select.component";
import {CommonModule} from "@angular/common";

@Component({
  standalone: true,
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  imports: [
    CommonModule,
    ComboSelectComponent,
    FeatherModule,
    FormsModule,
    SortMonitorsPipe
  ],
  providers: [SortMonitorsPipe],
  styleUrl: './statistics.component.scss'
})
export class StatisticsComponent implements OnInit {

  classes$: Observable<any> | undefined;
  monitors$: Observable<any> | undefined;
  nCodes$: Observable<any> | undefined;
  operatorsScapi$: Observable<any> | undefined;

  statisticTypes: { id: string; label: string }[] = [{
    id: 'final_result',
    label: 'NCode Bearbeitungsstand: Final Result'
  }, {
    id: 'zone',
    label: 'NCode Bearbeitungsstand: Zone'
  }, {
    id: 'user_now',
    label: 'User Bearbeitungsstand: aktuell'
  }, {
    id: 'user_history',
    label: 'User Bearbeitungsstand: historisch'
  }];

  scapiStates: { id: string; label: string }[] = [{
    id: 'new',
    label: 'Nicht bearbeitetet'
  }, {
    id: 'in_progress',
    label: 'In Bearbeitung'
  }, {
    id: 'finished',
    label: 'Abgeschlossen'
  }];

  statisticFilters: any = {
    type: [],
    assignedScapiUsername: [],
    scapiState: '',
    id: [],
    ncode: {classCode: [], code: []},
    results: {monitor: [], method: 'SCAPI'},
    date: {from: '', to: ''}
  }

  checkSfb: boolean | null = null;
  loading = false;
  loadingStatistics = false;
  filtersMinimized = false;
  statColumns: { readable: string, real: string }[] = [];
  statistics: any[] = [];
  statisticSorting: { direction: "ASC" | "DESC"; field: any } = {direction: 'ASC', field: 'id'};

  constructor(
    private graphql: GraphqlService,
    private sortMonitorsPipe: SortMonitorsPipe,
    private statesService: StatesService,
    private util: UtilService
  ) {
  }

  ngOnInit() {
    this.classes$ = this.graphql.queryField({
      field: 'classificationcode',
      attributes: ['code', 'label']
    }).pipe(map(classes =>
      classes.sort((classA: any, classB: any) => classA.code - classB.code)
    ));

    this.monitors$ = this.graphql.queryField({
      field: 'monitor',
      attributes: ['internalName', 'name']
    }).pipe(
      map(monitors =>
        monitors.sort((monitorA: any, MonitorB: any) => {
          if (monitorA.name < MonitorB.name) {
            return -1;
          } else if (monitorA.name > MonitorB.name) {
            return 1;
          } else {
            return 0;
          }
        })
      )
    );

    this.nCodes$ = this.graphql.queryField({field: 'ncode', attributes: ['code', 'codeLabel']}).pipe(map(nCodes =>
      nCodes.sort((codeA: any, codeB: any) => codeA.code - codeB.code)
    ));

    this.operatorsScapi$ = this.graphql.queryField({
      field: 'users',
      attributes: ['username'],
      filter: '{groups: [scapi]}'
    }).pipe(
      map((users: {username: string}[]) => {
        return [{username: 'nicht zugewiesen'}, ...users];
      })
    );

    this.resetStatisticsFilters();
  }

  toggleMinimizeFilters() {
    this.filtersMinimized = !this.filtersMinimized;
  }

  multiToMonoSelectStatistic() {
    if (this.statisticFilters.type.length > 1) {
      this.statisticFilters.type = this.statisticFilters.type.slice(-1);
    }
  }

  async requestStatistics() {
    this.statistics = [];
    this.statColumns = [];

    if (this.statisticFilters.type[0] === 'zone') {
      this.statistics = await firstValueFrom(this.graphql.queryField({
        field: 'statisticsByNcode',
        attributes: [
          'ncode {code, codeLabel, classCode}',
          'scapiDesignatedCount',
          'scapiNotEditedCount',
          'scapiEditingNotFinishedCount',
          'scapiEditingFinishedCount',
          'sfbDesignatedAndAssignedCount',
          'zone {name, count}']
      }));

      if (this.statistics) {
        const zones: string[] = this.statistics[0].zone.map((a: { name: string, count: number }) => a.name).sort();
        this.statColumns = [{readable: 'NCode-D', real: 'code'},
          {readable: 'Nutzart-Klasse', real: 'classCode'},
          {readable: 'Anzahl ges.', real: 'scapiDesignatedCount'},
          {readable: 'in/zur sFB', real: 'sfbDesignatedAndAssignedCount'},
          {readable: 'n.B.', real: 'scapiNotEditedCount'},
          {readable: 'i.B.', real: 'scapiEditingNotFinishedCount'},
          {readable: 'fin', real: 'scapiEditingFinishedCount'}];
        zones.forEach((zone: string) => {
          this.statColumns.push({readable: zone, real: zone});
        })

        this.statistics.forEach(ncodeStat => {
          ncodeStat['code'] = ncodeStat['ncode']['code'];
          ncodeStat['classCode'] = ncodeStat['ncode']['classCode'];
          zones.forEach((zoneName: string) => {
            ncodeStat[zoneName] = ncodeStat.zone.find((a: {
              name: string,
              count: number
            }) => a.name === zoneName)?.count;
          });
        });
      }
    } else if (this.statisticFilters.type[0] === 'final_result') {
      this.statistics = await firstValueFrom(this.graphql.queryField({
        field: 'statisticsByNcode',
        attributes: [
          'ncode {code, codeLabel, classCode}',
          'scapiDesignatedCount',
          'scapiNotEditedCount',
          'scapiEditingNotFinishedCount',
          'scapiEditingFinishedCount',
          'sfbDesignatedAndAssignedCount',
          'finalResults {monitor, greenCount, yellowCount, orangeCount, redCount}']
      }));

      if (this.statistics) {
        const results: string[] = this.statistics[0].finalResults.map((a: {
          monitor: string,
          greenCount: number,
          yellowCount: number,
          orangeCount: number,
          redCount: number
        }) => a.monitor);
        const sortedResults = this.sortMonitorsPipe.transformMonitorsArray(results);
        this.statColumns = [{readable: 'NCode-D', real: 'code'},
          {readable: 'Nutzart-Klasse', real: 'classCode'},
          {readable: 'Anzahl ges.', real: 'scapiDesignatedCount'},
          {readable: 'in/zur sFB', real: 'sfbDesignatedAndAssignedCount'},
          {readable: 'n.B.', real: 'scapiNotEditedCount'},
          {readable: 'i.B.', real: 'scapiEditingNotFinishedCount'},
          {readable: 'fin', real: 'scapiEditingFinishedCount'}];
        sortedResults.forEach((resultName: string) => {
          const readable = resultName.includes('NUTZ') ? 'NUTZ' : (resultName.includes('DGL') ? 'DGL' : (
            (resultName.includes('BRA') && resultName !== 'SPERR_BRA') ? 'BRA' : (resultName.includes('NBF') ? 'NBF' : 'SPERR_BRA')))
          this.statColumns.push({readable: readable + ' 00-09', real: resultName + ' 00-09'});
          this.statColumns.push({readable: readable + ' 10-20', real: resultName + ' 10-20'});
          this.statColumns.push({readable: readable + ' 50-80', real: resultName + ' 50-80'});
          this.statColumns.push({readable: readable + ' 90-99', real: resultName + ' 90-99'});
        });

        this.statistics.forEach(ncodeStat => {
          ncodeStat['code'] = ncodeStat['ncode']['code'];
          ncodeStat['classCode'] = ncodeStat['ncode']['classCode'];
          sortedResults.forEach((resultName: string) => {
            ncodeStat[resultName + ' 00-09'] = ncodeStat.finalResults.find(
              (a: {
                monitor: string,
                greenCount: number,
                yellowCount: number,
                orangeCount: number,
                redCount: number
              }) => a.monitor === resultName)?.greenCount;
            ncodeStat[resultName + ' 10-20'] = ncodeStat.finalResults.find(
              (a: {
                monitor: string,
                greenCount: number,
                yellowCount: number,
                orangeCount: number,
                redCount: number
              }) => a.monitor === resultName)?.yellowCount;
            ncodeStat[resultName + ' 50-80'] = ncodeStat.finalResults.find(
              (a: {
                monitor: string,
                greenCount: number,
                yellowCount: number,
                orangeCount: number,
                redCount: number
              }) => a.monitor === resultName)?.orangeCount;
            ncodeStat[resultName + ' 90-99'] = ncodeStat.finalResults.find(
              (a: {
                monitor: string,
                greenCount: number,
                yellowCount: number,
                orangeCount: number,
                redCount: number
              }) => a.monitor === resultName)?.redCount;
          });
        });
      }
    } else if (this.statisticFilters.type[0] === 'user_history') {
      const dateToObj = new Date(this.statisticFilters.date.to);
      dateToObj.setDate(dateToObj.getDate() + 1);
      const dateTo = dateToObj.toISOString().split('T')[0];
      let field = 'statisticsByOperator'
      if (this.statisticFilters.assignedScapiUsername) {
        // const test = this.statisticFilters.assignedScapiUsername.map((a: string) => "a").join(',');
        let filterString = this.util.stringifyGraphQL(this.statisticFilters.assignedScapiUsername);

        field += ` (operatorNames: ${filterString})`;
      }
      const statistics = await firstValueFrom(this.graphql.queryField({
        field: field,
        attributes: [
          'name',
          `byDay(dateRange: { _minInclusive: "${this.statisticFilters.date.from}" _maxExclusive: "${dateTo}"}) {date, finishedCount}`]
      }));

      const dates: string[] = statistics[0].byDay.map((a: { date: string, finishedCount: number }) => a.date);
      const users: string[] = statistics.map((a: any) => a.name);
      this.statColumns.push({readable: 'Datum', real: 'date'});

      users.forEach((user: string) => {
        this.statColumns.push({readable: user, real: user});
      })
      dates.forEach((date: string) => {
        const obj = Object.fromEntries(this.statColumns.map(k => [k.real, '']))
        const parsed = new Date(date);
        obj['date'] = parsed.toISOString().split('T')[0];
        statistics.forEach((userStat: any) => {
          obj[userStat.name] = userStat.byDay.find((a: {
            date: string,
            finishedCount: number
          }) => a.date === date)?.finishedCount;
        });
        this.statistics.push(obj);
      });

    } else if (this.statisticFilters.type[0] === 'user_now') {
      let field = 'statisticsByOperator'
      if (this.statisticFilters.assignedScapiUsername) {
        let filterString = this.util.stringifyGraphQL(this.statisticFilters.assignedScapiUsername);
        field += ` (operatorNames: ${filterString})`;
      }
      const statistics = await firstValueFrom(this.graphql.queryField({
        field: field,
        attributes: [
          'name',
          `current {scapiNotEditedCount, scapiEditingNotFinishedCount, scapiEditingFinishedCount}`]
      }));

      if (statistics) {
        this.statColumns = [
          {readable: 'User', real: 'user'},
          {readable: 'n.B.', real: 'scapiNotEditedCount'},
          {readable: 'i.B.', real: 'scapiEditingNotFinishedCount'},
          {readable: 'fin', real: 'scapiEditingFinishedCount'},
          {readable: 'Summe', real: 'sum'}];


        statistics.forEach((userStats: {
          name: string,
          current: {
            scapiNotEditedCount: number,
            scapiEditingNotFinishedCount: number,
            scapiEditingFinishedCount: number
          }
        }) => {
          let obj: any = {};
          obj['user'] = userStats.name;
          obj['scapiNotEditedCount'] = userStats.current.scapiNotEditedCount;
          obj['scapiEditingNotFinishedCount'] = userStats.current.scapiEditingNotFinishedCount;
          obj['scapiEditingFinishedCount'] = userStats.current.scapiEditingFinishedCount;
          obj['sum'] = obj['scapiNotEditedCount'] + obj['scapiEditingNotFinishedCount'] + obj['scapiEditingFinishedCount'];
          this.statistics.push(obj);
        });
      }

    }

    if (this.statistics && this.statisticFilters.type[0] !== 'user_now') {
      // add a sum to all counts
      const sum = Object.fromEntries(this.statColumns.map(k => [k, null]))
      this.statColumns.forEach(element => {
        if (!['code', 'classCode', 'date'].includes(element.real)) {
          sum[element.real] = this.statistics.reduce((total, obj) => obj[element.real] + total, 0)
        }
      });
      this.statistics.push(sum);
    }

    this.filtersMinimized = true;
  }

  resetStatisticsFilters() {
    const dateToday = new Date();
    const dateFrom = new Date();
    dateFrom.setDate(dateFrom.getDate() - 7);

    this.statisticFilters = {
      type: [],
      assignedScapiUsername: [],
      scapiState: null,
      id: [],
      ncode: {classCode: [], code: []},
      results: {monitor: [], method: 'SCAPI'},
      date: {from: dateFrom.toISOString().split('T')[0], to: dateToday.toISOString().split('T')[0]}
    }
    this.statistics = [];
  }

  getColumnClass(column: string) {
    if (['sfbDesignatedAndAssignedCount', 'date'].includes(column)) return 'rosa-background';
    else if (['scapiNotEditedCount', 'scapiEditingNotFinishedCount', 'scapiEditingFinishedCount'].includes(column)) return 'purple-background';
    else if (column.includes('00-09')) return 'left-border';
    else return null;
  }

  getColumnNameClass(column: string) {
    if (['sfbDesignatedAndAssignedCount', 'date'].includes(column)) return 'rosa-background';
    else if (['scapiNotEditedCount', 'scapiEditingNotFinishedCount', 'scapiEditingFinishedCount'].includes(column)) return 'purple-background';
    else if (column.includes('00-09')) return 'green-background left-border';
    else if (column.includes('10-20')) return 'yellow-background';
    else if (column.includes('50-80')) return 'orange-background';
    else if (column.includes('90-99')) return 'red-background';
    else return null;
  }

  getRowClass(codeRow: string | null, dateRow: string | null, userRow: string | null) {
    return (codeRow || dateRow || userRow) ? null : 'bold-text';
  }

  statisticSortBy(field: any) {
    const direction = this.statisticSorting && this.statisticSorting.field === field && this.statisticSorting.direction === 'ASC' ? 'DESC' : 'ASC';
    this.statisticSorting = {field, direction};
    if (field === 'user') {
      if (direction === 'ASC') {
        this.statistics.sort((a, b) => {
          return a[this.statisticSorting.field] > b[this.statisticSorting.field] ? -1 : 1
        });
      } else {
        this.statistics.sort((a, b) => {
          return b[this.statisticSorting.field] > a[this.statisticSorting.field] ? -1 : 1
        });
      }
    } else {
      if (direction === 'ASC') {
        this.statistics.sort((a, b) => a[this.statisticSorting.field] - b[this.statisticSorting.field]);
      } else {
        this.statistics.sort((a, b) => b[this.statisticSorting.field] - a[this.statisticSorting.field]);
      }
    }
  }

  saveStatistics() {
    const replacer = (key: string, value: number) => value === null ? '' : value;

    let csv = this.statistics.map(row => this.statColumns.map(fieldName => JSON.stringify(row[fieldName.real], replacer)).join(','));
    csv.unshift(this.statColumns.map(fieldName => fieldName.readable).join(','));
    let csvArray = csv.join('\r\n');

    const blob = new Blob([csvArray], {type: 'text/csv'});
    const date = new Date();
    saveAs(blob, "Statistics_" + this.statisticFilters.type + "_" + this.statesService.getActiveState()?.id + "_" + date.toISOString().split('T')[0] + ".csv");
  }

}
