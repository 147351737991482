import {Component, Input} from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-labeled-field',
  templateUrl: './labeled-field.component.html',
  styleUrls: ['./labeled-field.component.scss']
})
export class LabeledFieldComponent {
  @Input() label: string = '';
  @Input() value: string = '';
}
