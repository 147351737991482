import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SantaService {

  santaShown = false;

  async init() {
    const date = new Date();
    if (date.getMonth() === 11 && environment.holiday_special) {
      await this.showSanta();
    }
  }

  async showSanta() {
    if (!this.santaShown) {
      const img = new Image(320, 120);
      img.src = 'assets/img/santa_sleigh.gif';
      img.style.position = 'absolute';
      img.style.left = '0';
      img.style.bottom = '20px';
      const body = document.getElementsByTagName('body')[0];
      body.appendChild(img);

      for (let i = 0; i <= window.innerWidth; i++) {
        await (async () => {
          return new Promise(resolve => {
            setTimeout(() => {
              const left = Number.parseInt(img.style.left.split('px')[0]);
              img.style.left = (left + 1) + 'px';
              resolve(null);
            }, 3)
          })
        })();
      }
      body.removeChild(img);
      this.santaShown = true;
    }
  }
}
