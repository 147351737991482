import {Injectable} from '@angular/core';
import * as localForage from 'localforage';

@Injectable({
  providedIn: 'root'
})
/**
 * This service is used as an interface to whatever storage library (i.e. localforage) is used.
 */
export class StorageService {

  /**
   * Saves a string object in local storage
   *
   * @param key - Key under which the value is saved
   * @param value - The value to save
   * @return A promise containing the saved value or an error
   */
  set(key: string, value: string): Promise<string> {
    return localForage.setItem(key, value);
  }

  /**
   * Retrieves a saved value from local storage
   *
   * @param key - Key under which the value is saved
   * @return A promise containing the saved value or an error
   */
  get(key: string): Promise<any> {
    return localForage.getItem(key);
  }

  /**
   * Removes a saved value from local storage
   *
   * @param key - Key under which the value is saved
   * @return A promise containing an error if any have occurred
   */
  remove(key: string) {
    return localForage.removeItem(key);
  }

  /**
   * Clears all stored data
   */
  clear() {
    return localForage.clear();
  }
}
