import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PlotShortAttribute} from "../../model/plot";
import {AbstractPlotListComponent} from "../abstract-plot-list/abstract-plot-list.component";
import {FeatherModule} from "angular-feather";
import {FormsModule} from "@angular/forms";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {CommonModule} from "@angular/common";

@Component({
  standalone: true,
  selector: 'app-plot-list-single',
  templateUrl: './plot-list-single.component.html',
  imports: [
    CommonModule,
    FeatherModule,
    FormsModule,
    InfiniteScrollModule
  ],
  styleUrls: ['./plot-list-single.component.scss']
})
export class PlotListSingleComponent extends AbstractPlotListComponent implements OnInit {
  @Input() set extraFields(extraFields: PlotShortAttribute | PlotShortAttribute[] | undefined) {
    if (extraFields?.hasOwnProperty('length')) {
      this.columns = this.columns.concat(extraFields);
    } else if (extraFields) {
      this.columns.push(extraFields as PlotShortAttribute);
    }
  };

  @Input() showIndex: boolean = false;
  @Input() plotsCount: { total: number; open: number; edited: number; closed: number } | null = null;
  @Input() maxHeight: string | undefined;
  @Input() selectedPlotIds: number[] | null = null;

  @Output() plotSelected: EventEmitter<number> = new EventEmitter();
  @Output() requestStatesShown: EventEmitter<'open' | 'edited' | 'closed' | 'all'> = new EventEmitter();

  showSettings: boolean = false;
  plotStatesShown: 'open' | 'edited' | 'closed' | 'all' = 'all';

  constructor() {
    super();
  }

  ngOnInit() {
    // const plotStatesShownString = this.storage.get('plotStatesShown');
    // if (plotStatesShownString) {
    //   this.plotStatesShown = JSON.parse(plotStatesShownString);
    // }
  }

  toggleVisiblePlotsChooser() {
    this.showSettings = !this.showSettings;
  }

  // setFilters() {
  //   this.filteredPlots = this.filteredPlots?.filter((plot: PlotShort) => (plot.state === 'open' && this.plotStatesShown.open)
  //     || (plot.state === 'edited' && this.plotStatesShown.edited) || (plot.state === 'closed' && this.plotStatesShown.closed));
  // }

  onPlotStatesShownChange() {
    // this.storage.set('plotStatesShown', JSON.stringify(this.plotStatesShown));
    // this.setFilters();
    this.requestStatesShown.emit(this.plotStatesShown);
  }
}
