import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FeatherModule} from "angular-feather";
import {CommonModule} from "@angular/common";
import {DndHandleDirective, DndModule} from 'ngx-drag-drop';

@Component({
  standalone: true,
  selector: 'app-dashboard-item',
  templateUrl: './dashboard-item.component.html',
  imports: [
    FeatherModule,
    CommonModule,
    DndHandleDirective
  ],
  styleUrls: ['./dashboard-item.component.scss']
})
export class DashboardItemComponent {

  @Input() maximized: boolean | undefined;
  @Output() toggleMaximize: EventEmitter<null> = new EventEmitter();

  requestToggleMaximize() {
    this.toggleMaximize.next(null);
  }
}
