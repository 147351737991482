/*
 * Source: https://mariodante.medium.com/implementing-an-idle-user-detector-in-angular-without-external-libraries-598e75a8a8d4
 * last checked on 2024-08-19
 */

import {Injectable} from '@angular/core';
import {maxIdleTime} from '../constants';
import {userInactive, userTimeLeft} from '../events';

@Injectable({
  providedIn: 'root'
})
export class IdleService {
  private timeoutId: any;
  private countdownId: any;
  private countdownValue: number = maxIdleTime / 1000;

  constructor() {
    this.reset();
    this.initListener();
  }

  initListener() {
    window.addEventListener('mousemove', () => this.reset());
    window.addEventListener('click', () => this.reset());
    window.addEventListener('keypress', () => this.reset());
    window.addEventListener('DOMMouseScroll', () => this.reset());
    window.addEventListener('mousewheel', () => this.reset());
    window.addEventListener('touchmove', () => this.reset());
    window.addEventListener('MSPointerMove', () => this.reset());
  }

  reset() {
    clearTimeout(this.timeoutId);
    clearTimeout(this.countdownId);
    this.startCountdown();
  }

  startCountdown() {
    this.countdownValue = maxIdleTime / 1000;
    userTimeLeft.next(this.countdownValue);
    this.countdownId = setInterval(() => {
      this.countdownValue!--;
      userTimeLeft.next(this.countdownValue!);
      if (this.countdownValue! <= 0) {
        clearInterval(this.countdownId);
        userInactive.next(true);
      }
    }, 1000);
  }
}
