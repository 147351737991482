<div class="tabs dashboard-component-tabs is-boxed is-small is-marginless" >
  <ul>
    <li class="is-active">
      <a><span i18n>Plot Details</span></a>
    </li>
  </ul>
</div>

<div class="columns-wrapper" *ngIf="(plot$ | async | sortMonitors) as plot; else placeholder">
  <div class="columns has-background-grey-lighter">
    <div class="column has-text-weight-semibold" i18n>Application data</div>
  </div>
  <div class="columns">
    <div class="column has-text-weight-semibold">
      <app-labeled-field label="ID" value="{{plot.id}}"></app-labeled-field>
    </div>
    <div class="column">
      <app-labeled-field label="EFTAS-Zone" value="{{plot.eftasZone}}"></app-labeled-field>
    </div>
    <div class="column">
      <app-labeled-field label="EFTAS-ID" value="{{plot.eftasId}}"></app-labeled-field>
    </div>
  </div>
  <div class="columns">
    <div class="column has-text-weight-semibold">
      <app-labeled-field label="NCode" value="{{plot.nCodeDeclared?.code}}: {{plot.nCodeDeclared?.codeLabel}}"></app-labeled-field>
    </div>
  </div>
  <div class="columns">
    <div class="column">
      <app-labeled-field label="Area" i18n-label value="{{plot.area ? math.round(plot.area * 100) / 100 + 'ha' : null}}"></app-labeled-field>
    </div>
    <div class="column">
      <app-labeled-field label="Geometry size" i18n-label value=""></app-labeled-field>
    </div>
    <div class="column">
      <app-labeled-field label="Class" i18n-label value="{{plot.nCodeDeclared?.classCode}}"></app-labeled-field>
    </div>
  </div>


    <div class="columns has-background-grey-lighter">
      <div class="column has-text-weight-semibold">sFB</div>
    </div>
  <ng-container *ngIf="plot.getLastSfbAssessment() as assessmentSfb; else noSFB">
    <div class="columns">
      <div class="column">
        <app-labeled-field label="Operator" i18n-label value="{{assessmentSfb.operator}}"></app-labeled-field>
      </div>
      <div class="column">
        <app-labeled-field label="NCode-M" [ngClass]="{'primary': assessmentSfb.nCodeMeasured === plot.nCodeDeclared?.code, 'danger': assessmentSfb.nCodeMeasured !== plot.nCodeDeclared?.code}" value="{{assessmentSfb.nCodeMeasured}}"></app-labeled-field>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <app-labeled-field label="Comment" i18n-label value="{{assessmentSfb.comment}}"></app-labeled-field>
      </div>
      <div class="column" *ngIf="plot.getSfbTags() as sfbTags">
        <app-labeled-field label="Tags" i18n-label></app-labeled-field>
        <ul *ngFor="let tag of assessmentTags">
          <li *ngIf="sfbTags.includes(tag.name)">{{tag.label}}</li>
        </ul>
      </div>
    </div>

  </ng-container>

  <ng-template #noSFB>
    <div class="columns">
      <div class="column is-italic" i18n>No sFB results yet.</div>
    </div>
  </ng-template>


  <div class="columns has-background-grey-lighter">
    <div *ngIf="plot.finalResults.length >= 1; else noFinalResults" class="column has-text-weight-semibold">
      <a (click)="toggleExpandFinalResults()">
        <i-feather *ngIf="finalResultsExpanded; else chevronRight" name="chevron-down" class="icon-tiny"></i-feather>
        <ng-template #chevronRight>
          <i-feather name="chevron-right" class="icon-tiny"></i-feather>
        </ng-template>&nbsp;
        <ng-container i18n>Final results</ng-container>
      </a>
    </div>
    <ng-template #noFinalResults>
      <div class="column has-text-weight-semibold" i18n>
        Final results
      </div>
    </ng-template>
  </div>
  <ng-container *ngIf="plot.finalResults.length === 0">
    <div class="columns">
      <div class="column is-italic" i18n>No Final results yet.</div>
    </div>
  </ng-container>
  <ng-container *ngIf="finalResultsExpanded">
    <div *ngFor="let result of plot.finalResults">
      <div class="columns">
        <div class="column">
          <a (click)="toggleExpandFinalResult(result.monitor, result.updatedAt)">
            <div class="columns">
              <div class="column is-4 results-column">
                <i-feather *ngIf="isFinalResultExpanded(result.monitor, result.updatedAt); else chevronRight" name="chevron-down" class="icon-tiny"></i-feather>
                <ng-template #chevronRight>
                  <i-feather name="chevron-right" class="icon-tiny"></i-feather>
                </ng-template>
                {{ getMonitorShortLabel(result?.monitor) }}
              </div>
              <div class="column is-2 results-column">
                {{result?.nCodeMeasured}}
              </div>
              <div class="column is-3 results-column">
                {{result?.resultCode}}
              </div>
            </div>
          </a>
        </div>
      </div>
      <div *ngIf="isFinalResultExpanded(result.monitor,result.updatedAt)" class="results bottom-line">
        <div class="columns">
          <div class="column">
            <app-labeled-field label="Monitor" value="{{result.monitor}}"></app-labeled-field>
          </div>
          <div class="column">
            <app-labeled-field label="NCode-M" value="{{result.nCodeMeasured}}"></app-labeled-field>
          </div>
          <div class="column">
            <app-labeled-field label="Result code" i18n-label value="{{result.resultCode}}"></app-labeled-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <app-labeled-field label="Photo proof" i18n-label value="{{result.photoProof}}"></app-labeled-field>
          </div>
          <div class="column">
            <app-labeled-field label="Date" i18n-label value="{{result.date | date}}"></app-labeled-field>
          </div>
          <div class="column">
            <app-labeled-field label="Updated at" i18n-label value="{{result.updatedAt | date: 'short'}}"></app-labeled-field>
          </div>
        </div>
      </div>
    </div>
  </ng-container>


  <div class="columns has-background-grey-lighter">
    <div *ngIf="plot.results.length >= 1; else noResults" class="column has-text-weight-semibold">
      <a (click)="toggleExpandResults()">
        <i-feather *ngIf="resultsExpanded; else chevronRight" name="chevron-down" class="icon-tiny"></i-feather>
        <ng-template #chevronRight>
          <i-feather name="chevron-right" class="icon-tiny"></i-feather>
        </ng-template>&nbsp;
        <ng-container i18n>Results</ng-container>
      </a>
    </div>
    <ng-template #noResults>
      <div class="column has-text-weight-semibold" i18n>
        Results
      </div>
    </ng-template>
  </div>
  <ng-container *ngIf="plot.results.length === 0">
    <div class="columns">
      <div class="column is-italic" i18n>No Results yet.</div>
    </div>
  </ng-container>
  <ng-container *ngIf="resultsExpanded">
    <div *ngFor="let result of plot.results">
      <div class="columns">
        <div class="column">
          <a (click)="toggleExpandResult(result.monitor, result.updatedAt)">
            <div class="columns">
              <div class="column is-4 results-column">
                <i-feather *ngIf="isResultExpanded(result.monitor, result.updatedAt); else chevronRight"
                           name="chevron-down" class="icon-tiny"></i-feather>
                <ng-template #chevronRight>
                  <i-feather name="chevron-right" class="icon-tiny"></i-feather>
                </ng-template>
                {{ getMonitorShortLabel(result?.monitor) }}
              </div>
              <div class="column is-4 results-column">
                {{ result?.method }}
              </div>
              <div class="column is-2 results-column">
                {{ result?.date | date : 'dd.MM.' }}
              </div>
              <div class="column is-1 results-column">
                {{ result?.resultCode }}
              </div>
              <div class="column is-1 results-column">
                {{ result?.nCodeMeasured }}                
              </div>
            </div>
          </a>
        </div>
      </div>
      <div *ngIf="isResultExpanded(result.monitor, result.updatedAt)" class="results bottom-line">
        <div class="columns">
          <div class="column">
            <app-labeled-field label="Monitor" i18n-label value="{{result.monitor}}"></app-labeled-field>
          </div>
          <div class="column">
            <app-labeled-field label="Method" i18n-label value="{{result.method}}"></app-labeled-field>
          </div>
          <div class="column">
            <app-labeled-field label="Result" i18n-label value="{{result.result}} {{result.resultCode}}"></app-labeled-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <app-labeled-field label="NCode-M" value="{{result.nCodeMeasured}}"></app-labeled-field>
          </div>
          <div class="column">
            <app-labeled-field label="Date" i18n-label value="{{result.date | date}}"></app-labeled-field>
          </div>
          <div class="column">
            <app-labeled-field label="Updated at" i18n-label value="{{result.updatedAt | date: 'short'}}"></app-labeled-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <app-labeled-field label="Comment" i18n-label value="{{result.resultComment}}"></app-labeled-field>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<table *ngIf="false" class="table is-narrow is-fullwidth table-compact">
  <tbody>
      <!--
      <ng-container *ngFor="let key of plot.keys"
                    [ngTemplateOutlet]="plot[key] !== null && plot[key] !== undefined ? (!hasChildren(plot[key]) ? tableRow : (isArray(plot[key]) ? multiRow : subTable)) : tableRow"
                    [ngTemplateOutletContext]="{key: key, value: plot[key]}">
      </ng-container>
      -->
  </tbody>
</table>
<ng-template #placeholder>
  <div class="content-placeholder" i18n>No plot selected.</div>
</ng-template>

<ng-template #multiRow let-key='key' let-array='value'>
  <tr>
    <th colspan="2" class="is-clickable	" (click)="expand(key)">
      <i-feather *ngIf="isExpanded(key); else chevronRight" name="chevron-down" class="icon-tiny"></i-feather>
      <ng-template #chevronRight>
        <i-feather name="chevron-right" class="icon-tiny"></i-feather>
      </ng-template>
      {{key}}
    </th>
  </tr>
  <ng-container *ngIf="isExpanded(key)">
    <ng-container *ngFor="let entry of array"
                  [ngTemplateOutlet]="subTable"
                  [ngTemplateOutletContext]="{key: '', value: entry}">
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #tableRow let-key='key' let-value='value'>
  <tr>
    <th class="has-text-left">{{key}}</th>
    <td *ngIf="key === 'date' || key === 'updatedAt'; else nonDate" class="has-text-right">{{value | date:'short':'+0200':'de'}}</td>
    <ng-template #nonDate>
      <td class="has-text-right">{{value}}</td>
    </ng-template>
  </tr>
</ng-template>

<ng-template #subTable let-key='key' let-value='value'>
  <tr *ngIf="key && key.length >= 1">
    <th colspan="2" class="is-clickable" (click)="expand(key)">
      <i-feather *ngIf="isExpanded(key); else chevronRight" name="chevron-down" class="icon-tiny"></i-feather>
      <ng-template #chevronRight>
        <i-feather name="chevron-right" class="icon-tiny"></i-feather>
      </ng-template>
      {{key}}
    </th>
  </tr>
  <tr *ngIf="!key || isExpanded(key)">
    <td colspan="2">
      <table class="table is-narrow is-fullwidth table-compact">
        <tbody>
          <tr *ngFor="let subKey of getKeys(value)">
            <th>{{subKey}}</th>
            <td *ngIf="subKey === 'date' || subKey === 'updatedAt'; else nonDate" class="has-text-right">{{value[subKey] | date:'short':'+0200':'de'}}</td>
            <ng-template #nonDate>
              <td class="has-text-right">{{value[subKey]}}</td>
            </ng-template>
          </tr>
        </tbody>
      </table>
    </td>
  </tr>
</ng-template>
