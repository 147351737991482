<div class="message is-fullwidth" style="overflow-y: auto; max-height: calc(100vh - 125px);">

  <div class="message-header" (dblclick)="toggleMinimizeFilters()">
    <p i18n>Filters</p>
    <button (click)="toggleMinimizeFilters()">
      <span class="icon is-small">
        <i-feather *ngIf="!filtersMinimized; else minimized" name="chevron-up"></i-feather>
        <ng-template #minimized>
          <i-feather name="chevron-down"></i-feather>
        </ng-template>
      </span>
    </button>
  </div>

  <div *ngIf="!filtersMinimized" class="message-body">
    <div class="is-flex is-flex-direction-row is-flex-wrap-wrap filter-row unisized-labels checkbox-row">
      <div class="label label-select  is-flex is-flex-direction-column" style="width: 700px">
        <span i18n>Object IDs</span>
        <div>
          <textarea class="textarea" type="text" [ngModel]="objectIDsString" 
          rows="5" (ngModelChange)="setObjectIDsString($event)"></textarea>
        </div>
      </div>
      <div class="label label-select">
        <span i18n>Datasets</span><span *ngIf="dataFilters.type.length > 0">
          ({{ dataFilters.type.length }})</span>
        <div class="select is-fullwidth is-multiple">
          <select multiple [(ngModel)]="dataFilters.type">
            <ng-container>
              <option *ngFor="let source of sources" [ngValue]="source.id">{{ source.label }}</option>
            </ng-container>
          </select>
        </div>
      </div>
    </div>

    <button *ngIf="!isLoadingData; else loadingBtnData" class="button is-primary" (click)="requestData()"
      [disabled]="queryButtonDisabled()">
      <strong i18n>Apply filters</strong> 
    </button>
    <ng-template #loadingBtnData>
      <button class="button is-primary" disabled>
        <div class="loader"></div>&nbsp;
        <strong i18n>Apply filters</strong>
      </button> 
    </ng-template>
    &nbsp;
    <button class="button" (click)="resetDataFilters()" [disabled]="isLoadingData">
      <ng-container i18n>Reset filters</ng-container>
    </button>
    <div>
      <div class="columns" *ngIf="queryButtonDisabled()">
        <div class="column is-italic" i18n>No ID or NDVI dataset chosen (Sentinel/Planet).</div>
      </div>
    </div>
  </div>

  <div style="height: 100%">
    <div style="overflow: auto">
      <div *ngIf="allData" class="buttons are-small is-flex is-justify-content-center">
        <button class="button is-outlined is-primary" (click)="moveChart(-1)">
          <span class="icon">
            <i-feather name="chevron-left"></i-feather>
          </span>
        </button>
        <button class="button is-outlined is-primary" (click)="exportChart()">
          <span class="icon">
            <i-feather name="download"></i-feather>
          </span>
        </button>
        <button class="button is-outlined is-primary" (click)="moveChart(1)">
          <span class="icon">
            <i-feather name="chevron-right"></i-feather>
          </span>
        </button>
      </div>
      <div class="canvas-div" [ngClass]="{'invisible': !isDataLoaded}">
        <canvas id="chart" #canvas></canvas>
      </div>
    </div>
  </div>

  <div *ngIf="allData && allData.length === 0 && isDataLoaded">
    <div class="columns">
      <div class="column is-italic" i18n>No NDVI data found.</div>
    </div>
  </div>


</div>