<div class="message is-fullwidth">

  <div class="message-header" (dblclick)="toggleMinimizeFilters()">
    <p i18n>File upload</p>
    <button (click)="toggleMinimizeFilters()">
          <span class="icon is-small">
            <i-feather *ngIf="!filtersMinimized; else minimized" name="chevron-up"></i-feather>
            <ng-template #minimized>
              <i-feather name="chevron-down"></i-feather>
            </ng-template>
          </span>
    </button>
  </div>

  <div *ngIf="!filtersMinimized" class="message-body">

    <div class="file has-name is-boxed is-info">
      <label class="file-label">
        <input class="file-input" type="file" accept="text/csv" (change)="onSfbFileSelected($event)">
        <span class="file-cta">
              <span class="file-icon">
                <i-feather name="upload"></i-feather>
              </span>
              <span class="file-label" i18n>
                Choose a file…
              </span>
            </span>
        <span class="file-name" *ngIf="selectedSfbFile">{{selectedSfbFile.name}}</span>
      </label>
    </div>

  </div>
</div>

<div class="json-viewer-wrapper" *ngIf="assignments.length >= 1">
  <ngx-json-viewer [json]="assignments"></ngx-json-viewer>
</div>

<nav class="navbar is-fixed-bottom" *ngIf="assignments.length >= 1">
  <div class="navbar-end">
    <div class="navbar-item">
      <div class="buttons">
        <button class="button is-primary" (click)="sendSfbChanges()">
          <strong i18n>Send changes</strong>
        </button>
      </div>
    </div>
  </div>
</nav>

<div class="notification" *ngIf="loading">
  <span class="loader-mixin"></span>
  <p i18n>Assignments are being sent...</p>
</div>
