import {Component, EventEmitter, Input, Output} from '@angular/core';
import {
  PlotShort,
  PlotShortAttribute,
  PlotShortFieldLabels,
  StatisticsShortAttribute
} from "../../model/plot";
import {AbstractPlotListComponent} from "../abstract-plot-list/abstract-plot-list.component";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {FeatherModule} from "angular-feather";
import {CommonModule} from "@angular/common";

@Component({
  standalone: true,
  selector: 'app-plot-list-multiple',
  templateUrl: './plot-list-multiple.component.html',
  imports: [
    CommonModule,
    InfiniteScrollModule,
    FeatherModule
  ],
  styleUrls: ['./plot-list-multiple.component.scss']
})
export class PlotListMultipleComponent extends AbstractPlotListComponent {

  private previouslySelectedPlotIndex: number | undefined;

  @Input() set fields(fields: PlotShortAttribute | PlotShortAttribute[] | StatisticsShortAttribute[] | undefined) {
    if (fields?.hasOwnProperty('length')) {
      this.columns = fields as PlotShortAttribute[];
    } else if (fields) {
      this.columns = [fields as PlotShortAttribute];
    }
  };

  @Input() set extraFields(extraFields: PlotShortAttribute | PlotShortAttribute[] | undefined) {
    if (extraFields?.hasOwnProperty('length')) {
      this.columns = this.columns.concat(extraFields);
    } else if (extraFields) {
      this.columns.push(extraFields as PlotShortAttribute);
    }
  };

  @Input() showIndex: boolean = false;
  @Input() maxHeight: string | undefined;
  @Input() selectedPlotIds: number[] | null = null;

  @Output() plotsSelected: EventEmitter<number[]> = new EventEmitter();

  onPlotClick(event: MouseEvent, plot: PlotShort, index: number) {
    event.preventDefault();
    event.stopPropagation();

    const selectedPlotIs: number[] = [plot.id];

    // select multiple plots if user holds shift and there has been at least one selection previously
    if (event.shiftKey && this.previouslySelectedPlotIndex) {
      // selection is defined by the two indices
      const minIndex = Math.min(index, this.previouslySelectedPlotIndex);
      const maxIndex = Math.max(index, this.previouslySelectedPlotIndex);
      // select every plot within the interval ]minIndex, maxIndex[
      this.plots?.forEach((plt, idx) => {
        if (idx > minIndex && idx < maxIndex) {
          selectedPlotIs.push(plt.id);
        }
      });
    }

    this.plotsSelected.emit(selectedPlotIs);
    // save index of clicked plot for next operation
    this.previouslySelectedPlotIndex = index;
  }

  getCellClass(plot: PlotShort, column: PlotShortAttribute) {
    if (plot.finalResults) {
      const result = plot.finalResults.find(result => result.monitor === column);
      const value = result?.resultCode.toString();
      if (value) {
        const num = Number.parseInt(value.substring(value.length - 2), 10);
        if (num < 10) {
          return 'is-success';
        } else if (num >= 90) {
          return 'is-danger';
        } else {
          return 'is-warning';
        }
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  getColumnLabel(column: string) {
    return (PlotShortFieldLabels as any)[column] || column;
  }
}
