import {Routes} from "@angular/router";
import {AdministrationComponent} from "./administration.component";
import {ScapiAssignmentComponent} from "../scapi-assignment/scapi-assignment.component";
import {SfbAssignmentComponent} from "../sfb-assignment/sfb-assignment.component";
import {StatisticsComponent} from "../statistics/statistics.component";
import {AdminChartsComponent} from "../admin-charts/admin-charts.component";

export const administrationRoutes: Routes = [{
  path: '',
  component: AdministrationComponent,
  children: [{
    path: 'scapi-zuweisung',
    component: ScapiAssignmentComponent
  }, {
    path: 'sfb-zuweisung',
    component: SfbAssignmentComponent
  }, {
    path: 'statistik',
    component: StatisticsComponent
  }, {
    path: 'admin-charts',
    component: AdminChartsComponent
  }, {
    path: '',
    pathMatch: 'full',
    redirectTo: 'scapi-zuweisung'
  }]
}];
