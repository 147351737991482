import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PlotsService} from "../../services/plots.service";
import {
  ActivityTag,
  AssessmentScapiCreateInput, Assessment,
  LockoutActivityTag,
  NbfTag, AssessmentScapiUpdateInput, GreenhouseTag
} from '../../model/assessment';
import {NCode, PlotMonitors, Result} from '../../model/plot';
import {Subscription, firstValueFrom} from "rxjs";
import {FormsModule} from "@angular/forms";
import {BooleanPipe} from "../../pipes/boolean.pipe";
import {FeatherModule} from "angular-feather";
import {CommonModule} from "@angular/common";
import {toast} from "bulma-toast";
import {gridTemplatesChanged, requestRefreshPlot, selectedPlotsChange, stateSet, activeAssessmentStatusChange} from '../../events';

type CultivationOption = 'confirmed' | 'other' | 'nutzung_zusaetzlich' | 'nicht_bewertbar' | 'nutzung_unklar';
type ActivitySolvableOption = 'solvable' | 'not_solvable_yet' | 'permanently_unused' | 'not_solvable_in_scapi';
type MonitorsCommon = 'NUTZ' | 'DGL' | 'BRA' | 'SPERR_BRA' | 'NBF';
type GreenhouseTunnel = 'gewaechshaus_folientunnel';

@Component({
  standalone: true,
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  imports: [
    CommonModule,
    FormsModule,
    BooleanPipe,
    FeatherModule,
  ],
  styleUrls: ['./editor.component.scss']
})
export class EditorComponent implements OnInit, AfterViewInit, OnDestroy {

  private _footerEl: ElementRef | undefined;

  @ViewChild('editorFooter')
  set footerEl(ref: ElementRef) {
    this._footerEl = ref;
  }

  get footerEl(): ElementRef | undefined {
    return this._footerEl;
  }

  assessments: Assessment[] = [];
  newAssessment: AssessmentScapiCreateInput | undefined;

  activeAssessment: Assessment | AssessmentScapiCreateInput | undefined;

  activeTab: 'editor' | 'assessments' = 'editor';

  cultivation: CultivationOption | undefined;
  nCodeMeasured: number | null = null;

  activitySolvable: ActivitySolvableOption | undefined;

  plotMonitors: PlotMonitors | undefined;

  nbfCheck = false;

  activities = {
    taetigkeit_mahd_mulchen: false,
    taetigkeit_beweidung: false,
    taetigkeit_umbruch: false,
    taetigkeit_aussaat: false,
    taetigkeit_andere: false,
  }

  lockoutActivity = {
    taetigkeit_sperrzeitraum_revidiert: false,
    taetigkeit_sperrzeitraum_bestaetigt: false
  };

  nbf = {
    nbf_revidiert: false,
    nbf: false
  };

  greenHouse = {
    gewaechshaus_folientunnel: false
  };

  state: 'pristine' | 'dirty' | 'saved' = 'pristine';
  plotIds: number[] = [];
  nCodeDeclared: number = -1;
  comment: string = '';
  nCodes: NCode[] = [];

  // subscriptions
  gridTemplatesChangedSubscription: Subscription | undefined;
  selectedPlotChangeSubscription: Subscription | undefined;
  updateScapiAssessmentSubscription: Subscription | undefined;
  createScapiAssessmentSubscription: Subscription | undefined;

  plotsOnWatchlist: number[] = [];

  constructor(
    private editorEl: ElementRef,
    private plotService: PlotsService
  ) {
  }

  async ngOnInit() {

    this.selectedPlotChangeSubscription = selectedPlotsChange
      .subscribe(async data => {
        this.plotIds = data.plotIds;
        if (this.plotIds.length > 0) {
          await this.update();
          this.plotMonitors = await firstValueFrom(this.plotService.getPlotMonitors(this.plotIds[0]));
          this.setNbfState();
        } else {
          this.resetFields();
        }
      });

    this.nCodes = await firstValueFrom(this.plotService.getNCodes());
    this.nCodes.sort((a, b) => {
      if (a.code && b.code) {
        return a.code - b.code
      } else {
        return 0;
      }
    });
  }

  ngAfterViewInit() {
    console.log('ngAfterViewInit');
  }

  async update() {
    this.resetFields();
    await this.refreshPlotsOnWatchlist();
    this.nCodeDeclared = -1;
    const value = await firstValueFrom(this.plotService.getPlotDetailsByName(this.plotIds[0], 'nCodeDeclared {code}'));
    if (value && value.nCodeDeclared && value.nCodeDeclared.code) {
      this.nCodeDeclared = value.nCodeDeclared.code;
    }
    this.assessments = [];
    const assessments = await firstValueFrom(this.plotService.getScapiAssessments(this.plotIds[0]));
    if (assessments && assessments.length > 0) {
      this.assessments = assessments.sort((a, b) => {
        if (a.revision && b.revision) {
          return a.revision - b.revision;
        } else {
          return 0;
        }
      });
      const latestAssessment = this.assessments[this.assessments.length - 1];
      // if (latestAssessment && !latestAssessment.finished) {
      this.toggleActiveAssessment(latestAssessment as Assessment);
      // } else {
      // this.createNewAssessment();
      // }
    } else {
      this.createNewAssessment();
    }
  }

  createNewAssessment() {
    const latestAssessment = this.assessments[this.assessments.length - 1];
    if (latestAssessment) {
      this.newAssessment = {
        assessmentDate: new Date().toISOString(),
        featureId: this.plotIds[0],
        finished: false,
        tags: latestAssessment.tags
      };
      if (latestAssessment.comment) {
        this.newAssessment.comment = latestAssessment.comment;
      }
      if (latestAssessment.nCodeMeasured) {
        this.newAssessment.nCodeMeasured = latestAssessment.nCodeMeasured;
      }
    } else {
      this.newAssessment = {
        assessmentDate: new Date().toISOString(),
        featureId: this.plotIds[0],
        finished: false,
        tags: []
      };
    }
    this.toggleActiveAssessment(this.newAssessment);
    this.setActiveTab('editor');
    this.setState('pristine');
  }

  ngOnDestroy() {
    this.gridTemplatesChangedSubscription?.unsubscribe();
    this.selectedPlotChangeSubscription?.unsubscribe();
  }

  setState(state: 'pristine' | 'dirty' | 'saved') {
    this.state = state;
  }

  setActiveTab(activeTab: 'editor' | 'assessments') {
    this.activeTab = activeTab;
  }

  setNCode(cultivation: string) {
    if (cultivation !== 'other') {
      this.nCodeMeasured = null;
    }
  }

  resetFields(greenhouse: boolean = false) {
    this.cultivation = undefined;
    this.nCodeMeasured = null;
    this.activitySolvable = undefined;
    for (let key of Object.keys(this.activities)) {
      this.activities[key as ActivityTag] = false;
    }
    this.lockoutActivity.taetigkeit_sperrzeitraum_bestaetigt = false;
    this.lockoutActivity.taetigkeit_sperrzeitraum_revidiert = false;
    this.nbf.nbf = false;
    this.nbf.nbf_revidiert = false;
    if (!greenhouse) {
      this.greenHouse.gewaechshaus_folientunnel = false,
      this.comment = '';
    }
  }

  inflateFields() {
    this.resetFields();
    if (this.activeAssessment?.nCodeMeasured && this.nCodeDeclared) {
      if (this.activeAssessment.nCodeMeasured === this.nCodeDeclared) {
        this.cultivation = 'confirmed';
      } else {
        this.cultivation = 'other';
        this.nCodeMeasured = this.activeAssessment.nCodeMeasured;
      }
    }
    this.activeAssessment?.tags?.forEach(tag => {
      if (tag === 'nutzung_unklar') {
        this.cultivation = 'nutzung_unklar';
      } else if (tag === 'nutzung_zusaetzlich') {
        this.cultivation = 'nutzung_zusaetzlich';
      } else if (tag === 'nicht_bewertbar') {
        this.cultivation = 'nicht_bewertbar';
      } else if (tag === 'taetigkeit_keine') {
        this.activitySolvable = 'permanently_unused';
      } else if (tag === 'bisher_nicht_loesbar') {
        this.activitySolvable = 'not_solvable_yet';
      } else if (tag === 'taetigkeit_nicht_loesbar') {
        this.activitySolvable = 'not_solvable_in_scapi';
      } else if (this.activities.hasOwnProperty(tag)) {
        this.activities[tag as ActivityTag] = true;
        this.activitySolvable = 'solvable';
      } else if (this.lockoutActivity.hasOwnProperty(tag)) {
        this.lockoutActivity[tag as LockoutActivityTag] = true;
      } else if (this.nbf.hasOwnProperty(tag)) {
        this.nbf[tag as NbfTag] = true;
      } else if (this.greenHouse.hasOwnProperty(tag)) {
        this.greenHouse[tag as GreenhouseTag] = true;
      }

    });
    if (this.activeAssessment?.comment) {
      this.comment = this.activeAssessment?.comment;
    }
  }

  setComment(text: string) {
    this.comment = text;
  }

  sendChanges(finished: boolean = false) {
    const assessment: AssessmentScapiCreateInput = {
      assessmentDate: new Date().toISOString(),
      featureId: this.plotIds[0],
      finished,
      tags: []
    };

    // set cultivation tags and ncode measured
    if (this.cultivation === 'nutzung_unklar') {
      assessment.tags.push('nutzung_unklar');
    } else if (this.cultivation === 'nutzung_zusaetzlich') {
      assessment.tags.push('nutzung_zusaetzlich');
    } else if (this.cultivation === 'nicht_bewertbar') {
      assessment.tags.push('nicht_bewertbar');
    } else if (this.cultivation === 'confirmed' && this.nCodeDeclared) {
      assessment.nCodeMeasured = this.nCodeDeclared;
    } else if (this.cultivation === 'other' && this.nCodeMeasured) {
      assessment.nCodeMeasured = this.nCodeMeasured;
    }
    if (this.activitySolvable === 'permanently_unused') {
      assessment.tags.push('taetigkeit_keine');
    } else if (this.activitySolvable === 'not_solvable_yet') {
      assessment.tags.push('bisher_nicht_loesbar');
    } else if (this.activitySolvable === 'not_solvable_in_scapi') {
      assessment.tags.push('taetigkeit_nicht_loesbar');
    }

    // set activity tags
    const activityTags = Object.entries(this.activities)
      .filter((entry: [string, boolean]) => entry[1])
      .map((entry: [string, boolean]) => entry[0] as ActivityTag);
    assessment.tags = assessment.tags.concat(activityTags);

    // set lockoutActivity tag
    const lockoutActivityTags = Object.entries(this.lockoutActivity)
      .filter((entry: [string, boolean]) => entry[1])
      .map((entry: [string, boolean]) => entry[0] as LockoutActivityTag);
    assessment.tags = assessment.tags.concat(lockoutActivityTags);

    // set nbf tag
    const nbfTags = Object.entries(this.nbf)
      .filter((entry: [string, boolean]) => entry[1])
      .map((entry: [string, boolean]) => entry[0] as NbfTag);
    assessment.tags = assessment.tags.concat(nbfTags);

    // set greenhouse tag
    const greenHouseTags = Object.entries(this.greenHouse)
      .filter((entry: [string, boolean]) => entry[1])
      .map((entry: [string, boolean]) => entry[0] as GreenhouseTag);
    assessment.tags = assessment.tags.concat(greenHouseTags);

    if (this.comment.length > 0) {
      assessment.comment = this.comment;
    }

    let successful = false;

    if (this.activeAssessment && this.activeAssessment.hasOwnProperty('revision') && this.activeAssessment.hasOwnProperty('finished') && !(this.activeAssessment as Assessment).finished) {
      const assessmentUpdate: AssessmentScapiUpdateInput = Object.assign({}, assessment) as any;
      assessmentUpdate.revision = (this.activeAssessment as Assessment).revision;
      this.updateScapiAssessmentSubscription?.unsubscribe();
      this.updateScapiAssessmentSubscription = this.plotService.updateScapiAssessment(assessmentUpdate)
        .subscribe(async (response) => {
          if (response) { successful = true; }
          await this.update();
          requestRefreshPlot.next({plotId: this.plotIds[0], operation: 'update'});
        });
    } else {
      this.createScapiAssessmentSubscription?.unsubscribe();
      this.createScapiAssessmentSubscription = this.plotService.createScapiAssessment(assessment)
        .subscribe(async (response) => {
          if (response) { successful = true; }
          await this.update();
          requestRefreshPlot.next({plotId: this.plotIds[0], operation: 'update'});
        });
    }

    if (successful) {
      let message: string;
      if (finished) {
        const closed = $localize`:@@closed:closed`;
        message = `${this.plotIds[0]} ${closed}`;
      } else {
        const saved = $localize`:@@saved:saved`;
        message = `${this.plotIds[0]} ${saved}`;
      }
      toast({
        message,
        position: "bottom-center",
        type: "is-info",
        extraClasses: "is-light"
      });
    }
    
  }

  onActivitySolvableChange(type: ActivitySolvableOption) {
    if (type !== 'solvable') {
      for (let key of Object.keys(this.activities)) {
        this.activities[key as ActivityTag] = false;
      }
    }
    this.setState('dirty');
  }

  onActivityChange(type: ActivityTag, checked: boolean) {
    this.activities.taetigkeit_mahd_mulchen = checked && type === 'taetigkeit_mahd_mulchen';
    this.activities.taetigkeit_beweidung = checked && type === 'taetigkeit_beweidung';
    this.activities.taetigkeit_umbruch = checked && type === 'taetigkeit_umbruch';
    this.activities.taetigkeit_aussaat = checked && type === 'taetigkeit_aussaat';
    this.activities.taetigkeit_andere = checked && type === 'taetigkeit_andere';
    if (checked) {
      this.activitySolvable = 'solvable';
    } else {
      this.activitySolvable = undefined;
    }
    this.setState('dirty');
  }

  onLockoutActivityChange(type: 'revised' | 'confirmed', checked: boolean) {
    this.lockoutActivity.taetigkeit_sperrzeitraum_revidiert = checked && type === 'revised';
    this.lockoutActivity.taetigkeit_sperrzeitraum_bestaetigt = checked && type === 'confirmed';
    this.setState('dirty');
  }

  onNbfChange(type: 'revised' | 'confirmed', checked: boolean) {
    this.nbf.nbf_revidiert = checked && type === 'revised';
    this.nbf.nbf = checked && type === 'confirmed';
    this.setState('dirty');
  }

  onGreenhouseChange(checked: boolean) {
    // deactivate everything if true
    if (checked){
      this.resetFields(true);
    }
    this.setState('dirty');
  }

  toggleActiveAssessment(assessment: Assessment | AssessmentScapiCreateInput) {
    if (this.activeAssessment === assessment) {
      if (assessment.finished) {
        this.activeAssessment = undefined;
        this.resetFields();
      }

    } else {
      this.activeAssessment = assessment;
      this.inflateFields();
    }
    this.setState('saved');
    const finished = this.activeAssessment?.finished ? true : false;
    activeAssessmentStatusChange.next({
      finished: finished
    });
  }

  finish() {
    this.sendChanges(true);
  }

  getRevision() {
    return (this.activeAssessment as Assessment).revision;
  }

  monitorFinished(type: MonitorsCommon) {
    const currentMonitor = this.plotMonitors?.finalResults?.filter(a => (a.monitor === 'SPERR_BRA' && type === a.monitor) || a.monitor?.includes(type))[0];
    const mod = currentMonitor?.resultCode ? currentMonitor.resultCode % 100 : 0;
    return mod !== 10 && mod !== 20;
  }

  violationMonitorFinished(type: MonitorsCommon) {
    const currentMonitor = this.plotMonitors?.finalResults?.filter(a => (a.monitor === 'SPERR_BRA' && type === a.monitor) || a.monitor?.includes(type))[0];
    const mod = currentMonitor?.resultCode ? currentMonitor.resultCode % 100 : -1;
    return !(mod === 0 || mod === 1 || mod === 20);
  }

  isOnWatchList(plotId: number) {
    return this.plotsOnWatchlist.indexOf(plotId) !== -1;
  }

  async addToWatchList(plotId: number) {
    await firstValueFrom(this.plotService.addPlotToWatchlist(plotId));
    await this.refreshPlotsOnWatchlist();
    requestRefreshPlot.next({plotId: plotId, operation: 'add', watchlist: true});
  }

  async removeFromWatchList(plotId: number) {
    await firstValueFrom(this.plotService.removePlotFromWatchlist(plotId));
    await this.refreshPlotsOnWatchlist();
    requestRefreshPlot.next({plotId: plotId, operation: 'remove', watchlist: true});
  }

  private async refreshPlotsOnWatchlist() {
    this.plotsOnWatchlist = await firstValueFrom(this.plotService.getPlotIdsOnWatchList());
  }

  private setNbfState() {
    stateSet.subscribe({
      next: async (state) => {
        const monitor = state.id === 'NW' ? 'ALL_NBF' : 'NBF';
        if (this.plotIds.length > 0) {
          const results = await firstValueFrom(this.plotService.getResults(this.plotIds[0]));
          if (results && results.length > 0) {
            let latestNbfSentinelResult;
            let latestNbfScapiResult;
            const nbfSentinelResults = results.filter(result => result.monitor === monitor && result.method === 'SENTINEL');
            if (nbfSentinelResults && nbfSentinelResults.length > 0) {
              latestNbfSentinelResult = nbfSentinelResults
                .sort((a, b) => new Date(a.date as string).getDate() - new Date(b.date as string).getDate())[0];
            }
            const nbfScapiResults = results.filter(result => result.monitor === monitor && result.method === 'SCAPI');
            if (nbfScapiResults && nbfScapiResults.length > 0) {
              latestNbfScapiResult = nbfScapiResults
                .sort((a, b) => new Date(a.date as string).getDate() - new Date(b.date as string).getDate())[0];
            }
            this.nbfCheck = latestNbfSentinelResult?.resultCode === 90 && (!latestNbfScapiResult || (latestNbfScapiResult.resultCode !== 0 && latestNbfScapiResult.resultCode !== 90));
          }
        }
      }
    });
  }
}
