<!--<div class="control has-icons-left">-->
<!--  <input class="input is-small" type="text" placeholder="Search" i18n-placeholder="Search"-->
<!--    (change)="onSearchInputChange($event)">-->
<!--  <span class="icon is-small is-left">-->
<!--    <i-feather name="search" class="icon-smaller"></i-feather>-->
<!--  </span>-->
<!--  <span class="icon is-small is-right is-clickable" (click)="showSearchInfo($event)">-->
<!--    <i-feather name="help-circle" class="icon-smaller"></i-feather>-->
<!--  </span>-->
<!--</div>-->

<div class="scroll-wrapper" infiniteScroll (scrolled)="onScroll()" [scrollWindow]="false" [ngStyle]="{'max-height': maxHeight}">
  <table class="table is-narrow is-hoverable is-fullwidth table-compact">
    <thead>
      <tr>
        <th *ngIf="showIndex" class="has-text-right" style="width: 50px">#</th>
        <th *ngFor="let column of columns; let i = index" (click)="sortBy(column)" [ngStyle]="{'width': getColumnType(column) === 'number' ? '120px' : 'initial'}">
          <div class="cell-wrapper is-flex is-flex-direction-row is-align-items-center"
            [ngClass]="[getColumnType(column) === 'number' ? 'is-justify-content-flex-end' : 'is-justify-content-flex-start']">
            <ng-container *ngIf="sorting && sorting.field === column; else isSortable">
              <i-feather class="sort-direction-indicator" *ngIf="sorting.direction === 'ASC'; else desc" name="chevron-up"></i-feather>
              <ng-template #desc>
                <i-feather class="sort-direction-indicator" name="chevron-down"></i-feather>
              </ng-template>
            </ng-container>
            <ng-template #isSortable>
              <ng-container *ngIf="this.sortableColumns.includes(column)">
                <i-feather class="sort-direction-indicator" name="chevron-right"></i-feather>
              </ng-container>
            </ng-template>

            {{getColumnLabel(column)}}
          </div>
        </th>
      </tr>
    </thead>
    <tbody >
      <tr *ngFor="let plot of filteredPlots; let i = index" (click)="onPlotClick($event, plot, i)"
        [ngClass]="{'is-selected': selectedPlotIds && selectedPlotIds.indexOf(plot.id) !== -1}">
        <td *ngIf="showIndex" class="has-text-right">{{i}}</td>
        <td *ngFor="let column of columns" title="{{plot.getAsString(column)}}" [class]="getCellClass(plot, column)"
          [ngClass]="[getColumnType(column) === 'number' ? 'has-text-right' : 'has-text-left']">
          {{plot.getAsString(column)}}
        </td>
      </tr>
    </tbody>
  </table>
</div>
