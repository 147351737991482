import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  standalone: true,
  name: 'boolean'
})
/**
 * This pipe accepts a boolean value and renders ✔ for true and ✘ for false values.
 */
export class BooleanPipe implements PipeTransform {

  transform(value: boolean | undefined, ...args: unknown[]): string {
    if (value !== undefined) {
      return value ? '&#10004;' : '&#10008;';
    } else {
      return '';
    }
  }

}
