import {AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {StorageService} from '../../services/storage.service';
import {Subscription} from "rxjs";
import {PlotsComponent} from "../plots/plots.component";
import {ActivatedRoute, Params} from "@angular/router";
import {SantaService} from "../../services/santa.service";
import {ImageGalleryComponent} from "../image-gallery/image-gallery.component";
import {DashboardItemComponent} from "../dashboard-item/dashboard-item.component";
import {ChartComponent} from "../chart/chart.component";
import {PlotDetailsComponent} from "../plot-details/plot-details.component";
import {EditorComponent} from "../editor/editor.component";
import {EventsComponent} from "../events/events.component";
import {MapComponent} from "../map/map.component";
import {CommonModule} from "@angular/common";
import {dashboardResized, gridTemplatesChanged, requestResetDashboardLayout, selectedPlotsChange} from '../../events';
import {DndDraggableDirective, DndDropEvent, DndDropzoneDirective} from 'ngx-drag-drop';
import {defaultComponentPositions} from '../../constants';

@Component({
  standalone: true,
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  imports: [
    CommonModule,
    ImageGalleryComponent,
    DashboardItemComponent,
    ChartComponent,
    PlotDetailsComponent,
    EditorComponent,
    EventsComponent,
    MapComponent,
    PlotsComponent,
    DndDropzoneDirective,
    DndDraggableDirective
  ],
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {

  @Output('gridSet') gridSet: EventEmitter<any> = new EventEmitter();

  @ViewChild(MapComponent) map: MapComponent | undefined;

  @ViewChild('grid')
  set grid(element: ElementRef | undefined) {
    this.gridElements.grid = element?.nativeElement;
    if (element) {
      // after grid has been created notify parent component to reset grid size
      this.gridSet.emit();
    }
  }

  get grid(): any {
    return this.gridElements.grid;
  }

  @ViewChild('rightcol')
  set rightcol(element: ElementRef | undefined) {
    this.gridElements.rightcol = element?.nativeElement
  }

  get rightcol(): any {
    return this.gridElements.rightcol;
  }

  @ViewChild('leftcol')
  set leftcol(element: ElementRef | undefined) {
    this.gridElements.leftcol = element?.nativeElement
  }

  get leftcol(): any {
    return this.gridElements.leftcol;
  }

  @ViewChild('centerbottom')
  set centerbottom(element: ElementRef | undefined) {
    this.gridElements.centerbottom = element?.nativeElement
  }

  get centerbottom(): any {
    return this.gridElements.centerbottom;
  }

  dragging: 'right' | 'left' | 'center-bottom' | 'left-center' | 'right-center' | 'top-center' | 'bottom-center' | 'none' = 'none';

  gridElements: { grid: any, rightcol: any, centerbottom: any, leftcol: any } = {
    grid: undefined,
    rightcol: undefined,
    centerbottom: undefined,
    leftcol: undefined
  };
  maximizedItem: string = "";
  selectedPlotIds: number[] = [];

  subscriptions: Subscription[] = [];

  params: Params = {};

  componentPositions : any = null;

  constructor(
    private route: ActivatedRoute,
    private storageService: StorageService,
    private santa: SantaService
  ) {
  }

  async ngOnInit() {
    this.subscriptions.push(requestResetDashboardLayout.subscribe(() => this.resetGridTemplates()));

    this.subscriptions.push(selectedPlotsChange.subscribe(data => {
      if (data.origin !== 'DashboardComponent') {
        this.selectedPlotIds = data.plotIds;
        this.setFeatureUrlParameter();
      }
    }));

    // this.subscriptions.push(this.route.params.subscribe(params => params['plotId'] ? selectedPlotsChange$.next([+params['plotId']]) : null));
    this.route.queryParams.subscribe((params: Params) => {
      this.params = params;
      if (params['feature']) {
        const features = params['feature']
          .split(',')
          .map((feature: string) => +feature);
        this.selectedPlotIds = features;
        selectedPlotsChange.next({plotIds: features, origin: 'DashboardComponent'});
      }
    });

    await this.restoreComponentPositions();
  }

  async ngAfterViewInit() {
    await this.restoreGridTemplates();
    await this.santa.init();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  private setFeatureUrlParameter() {
    // Get the current URL
    const url = new URL(window.location.href);

    // Get the URLSearchParams object from the URL
    const searchParams = url.searchParams;

    const features = this.selectedPlotIds.join(',');

    // Check if the customer parameter exists in the URL
    if (searchParams.has('feature')) {
      // Get the current value of the parameter
      const paramValue = searchParams.get('feature');

      if (features.length === 0) {
        // Delete if cleared (e.g. Bundesland changed)
        searchParams.delete('feature');
      }

      // Check if the value is different from the desired value
      if (paramValue !== features) {
        if (features.length === 0) {
          // Delete if cleared (e.g. Bundesland changed)
          searchParams.delete('feature');
        } else {
          // Update the parameter value
          searchParams.set('feature', features);
        }
      }
    } else if (features.length > 0) {
      // Add the parameter to the URL
      searchParams.append('feature', features);
    }
    // Create a new URL with the modified parameters
    const newUrl = url.origin + url.pathname + '?' + searchParams.toString();

    // Modify the browser history without reloading the page
    window.history.replaceState({}, '', newUrl);
  }

  setCursor(cursor: string) {
    if (this.grid) {
      this.grid.style.cursor = cursor;
    }
  }

  startDragging(side: 'right' | 'left' | 'center-bottom' | 'left-center' | 'right-center' | 'top-center' | 'bottom-center') {
    this.dragging = side;
    if (['center-bottom', 'left-center', 'right-center'].indexOf(side) !== -1) {
      this.setCursor('ns-resize');
    } else {
      this.setCursor('ew-resize');
    }
  }

  endDrag() {
    if (this.dragging !== 'none') {
      this.dragging = 'none';
      dashboardResized.next();
      this.setCursor('auto');
    }
  }

  async onDrag(event: MouseEvent) {
    if (this.dragging !== 'none' && this.grid) {
      const dragbarSize = 2;
      // three elements
      if ((this.dragging === 'left' || this.dragging === 'right') && this.leftcol && this.rightcol) {
        const leftSize = this.dragging === 'left' ? event.clientX : this.leftcol.clientWidth;
        const rightSize = this.dragging === 'right' ? this.grid.clientWidth - event.clientX : this.rightcol.clientWidth;
        const centerSize = this.grid.clientWidth - (2 * dragbarSize) - leftSize - rightSize;
        const tmpl = [
          leftSize + 'fr',
          dragbarSize + 'px',
          centerSize + 'fr',
          dragbarSize + 'px',
          rightSize + 'fr'
        ];
        this.grid.style.gridTemplateColumns = tmpl.join(' ');
        event.preventDefault();
      } else if (['left-center', 'right-center', 'center-bottom'].indexOf(this.dragging) !== -1) {
        const topSize = event.clientY - this.grid.offsetTop;
        const bottomSize = this.grid.clientHeight - topSize;
        let tmpl = [
          topSize + 'fr',
          dragbarSize + 'px',
          bottomSize + 'fr'
        ];
        let newColDef = tmpl.join(' ');
        if (this.dragging === 'left-center' && this.leftcol) {
          this.leftcol.style.gridTemplateRows = newColDef;
        } else if (this.dragging === 'right-center' && this.rightcol) {
          this.rightcol.style.gridTemplateRows = newColDef;
        } else if (this.dragging === 'center-bottom') {
          this.grid.style.gridTemplateRows = newColDef;
        }
        event.preventDefault();
      } else if (this.dragging === 'bottom-center' && this.leftcol && this.centerbottom) {
        const leftSize = event.clientX - this.leftcol.clientWidth - dragbarSize / 2;
        const centerEl = this.centerbottom;
        const rightSize = (centerEl.clientWidth - leftSize) - dragbarSize;
        let tmpl = [
          (leftSize / (centerEl.clientWidth - dragbarSize)) + 'fr',
          dragbarSize + 'px',
          (rightSize / (centerEl.clientWidth - dragbarSize)) + 'fr'
        ];
        let newRowDef = tmpl.join(' ');
        this.centerbottom.style.gridTemplateColumns = newRowDef;
        this.gridElements.centerbottom.gridTemplateRows = newRowDef;
        event.preventDefault();
      }
      await this.saveGridTemplates();
    }
  }

  /**
   * Saves currently used grid layouts to local storage.
   * @private
   */
  private async saveGridTemplates() {
    const templates: any = {};
    for (let [key, value] of Object.entries(this.gridElements)) {
      if (value.style && (value.style.gridTemplateColumns || value.style.gridTemplateRows)) {
        templates[key] = {
          gridTemplateColumns: value.style.gridTemplateColumns,
          gridTemplateRows: value.style.gridTemplateRows
        };
      }
    }
    console.log('templates', templates);
    gridTemplatesChanged.next(templates);
    await this.storageService.set('gridTemplates', JSON.stringify(templates));
  }

  /**
   * Restores previously saved grid layout from local storage.
   * @private
   */
  private async restoreGridTemplates() {
    const templatesString = await this.storageService.get('gridTemplates') as string;
    if (templatesString) {
      const templates = JSON.parse(templatesString);
      for (let [key, val] of Object.entries(templates)) {
        const value: any = val as any;
        const element = this.gridElements[key as 'grid' | 'rightcol' | 'centerbottom' | 'leftcol'];
        if (value.gridTemplateColumns) {
          element.style.gridTemplateColumns = value.gridTemplateColumns;
        }
        if (value.gridTemplateRows) {
          element.style.gridTemplateRows = value.gridTemplateRows;
        }
      }
      gridTemplatesChanged.next(templates);
      dashboardResized.next();
    }
  }

  /**
   * Resets all grids to default layouts.
   */
  private async resetGridTemplates() {
    this.grid.style.gridTemplateColumns = '2fr 2px 6fr 2px 2fr';
    this.grid.style.gridTemplateRows = '6fr 2px 2fr';
    this.centerbottom.style.gridTemplateColumns = '2fr 2px 1fr';
    this.leftcol.style.gridTemplateRows = '1fr 2px 2fr';
    this.rightcol.style.gridTemplateRows = '1fr 2px 2fr';
    this.componentPositions = defaultComponentPositions;
    dashboardResized.next();
    await this.saveGridTemplates();
    await this.saveComponentPositions();
  }

  /**
   * Sets a component as maximized
   * @param item
   */
  toggleMaximizeItem(item: string) {
    if (this.maximizedItem === item) {
      this.maximizedItem = "";
    } else {
      this.maximizedItem = item;
    }
  }

  async onDrop(event: DndDropEvent, destination: string) {
    const a = document.getElementById(event.data);
    const b = document.getElementById(destination)?.children.item(0) as HTMLElement;
    if (a && b && a !== b) {
      await this.switchComponents(a, b);
    }
  }

  async switchComponents(a: HTMLElement, b: HTMLElement) {
    const parentA = a.parentElement;
    const parentB = b.parentElement;
    if (parentA && parentB) {
      this.componentPositions[parentA.id] = b.id;
      this.componentPositions[parentB.id] = a.id;
    }
    await this.saveComponentPositions();
  }

  async saveComponentPositions() {
    await this.storageService.set('components', JSON.stringify(this.componentPositions));
  }

  async restoreComponentPositions() {
    const string = await this.storageService.get('components');
    if (!string) {
      this.componentPositions = defaultComponentPositions;
    } else {
      this.componentPositions = JSON.parse(string);
    }
  }

  getDragImageOffset(event: DragEvent, dragImage: Element): {x: number; y: number} {
    return {x: (event.currentTarget as HTMLElement).clientWidth - 38, y: 16};
  }
}
