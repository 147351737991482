<div class="scapi-wrapper" [ngClass]="{'has-navbar-fixed-bottom': assignment.plotIds.length > 0}"
     (click)="hideSelectableFields()">

  <div class="message is-fullwidth">

    <div class="message-header" (click)="toggleMinimizeFilters()" (dblclick)="toggleMinimizeFilters()">
      <p i18n>Filters</p>
      <div class="flex-spacer"></div>
      <p i18n>Page size</p>
      <input class="input input-pagesize" type="number" [(ngModel)]="pageSize" (keydown)="setPageSize($event)" (dblclick)="preventDefault($event)" (click)="preventDefault($event)" placeholder="Text input">
      <button class="btn-toggle-minimize">
          <span class="icon is-small">
            <i-feather *ngIf="!filtersMinimized; else minimized" name="chevron-up"></i-feather>
            <ng-template #minimized>
              <i-feather name="chevron-down"></i-feather>
            </ng-template>
          </span>
      </button>
    </div>

    <div *ngIf="!filtersMinimized" class="message-body">
      <div class="is-flex is-flex-direction-row is-flex-wrap-wrap filter-row unisized-labels">
        <div class="label label-select">
          <span i18n>NCode-D</span><span *ngIf="filters.ncode.code.length > 0">
                ({{ filters.ncode.code.length }})</span>
          <app-combo-select *ngIf="(nCodes$ | async) as nCodes" [options]="nCodes"
                            [labelAttr]="['code', 'codeLabel']" valueAttr="code" [(ngModel)]="filters.ncode.code"/>
        </div>

        <div class="label label-select">
          <span i18n>NCode-M</span><span *ngIf="filters.assessmentScapi.nCodeMeasured.length > 0">
                ({{ filters.assessmentScapi.nCodeMeasured.length }})</span>
          <app-combo-select *ngIf="(nCodes$ | async) as nCodes" [options]="nCodes"
                            [labelAttr]="['code', 'codeLabel']" valueAttr="code"
                            [(ngModel)]="filters.assessmentScapi.nCodeMeasured"/>
        </div>

        <div class="label label-select">
          <span i18n>Zone</span><span *ngIf="filters.eftasZones.length > 0"> ({{ filters.eftasZones.length }})</span>
          <app-combo-select *ngIf="(eftasZones$ | async) as zones" [options]="zones" [labelAttr]="['name']"
                            valueAttr="name" [(ngModel)]="filters.eftasZones"/>
        </div>

        <div class="label label-select">
          <span i18n>Class</span><span *ngIf="filters.ncode.classCode.length > 0">
                ({{ filters.ncode.classCode.length }})</span>
          <app-combo-select *ngIf="(classes$ | async) as classes" [options]="classes"
                            [labelAttr]="['code', 'label']" valueAttr="code" [(ngModel)]="filters.ncode.classCode"/>
        </div>

        <div class="label label-select">
          <span i18n>On sFB task list</span><span *ngIf="filters.assignedSfbUsername.length > 0">
                ({{ filters.assignedSfbUsername.length }})</span>
          <app-combo-select [options]="(operatorsSfb$ | async)" [labelAttr]="['username']" valueAttr="username"
                            [(ngModel)]="filters.assignedSfbUsername"/>
        </div>

        <div class="label label-select">
          <div class="control">
            <ng-container i18n>On sCAPI</ng-container>&nbsp;
            <label class="radio">
              <input type="radio" name="scapi_operator_mode" value="task-list" style="margin-right: 3px"
                     [(ngModel)]="scapiOperatorMode">
              <ng-container i18n>task list</ng-container>
            </label>
            <label class="radio">
              <input type="radio" name="scapi_operator_mode" value="watch-list" style="margin-right: 3px"
                     [(ngModel)]="scapiOperatorMode">
              <ng-container i18n>watch list</ng-container>
            </label>
            <span *ngIf="filters.assignedScapiUsername.length > 0">
                  ({{ filters.assignedScapiUsername.length }})</span>
          </div>
          <app-combo-select [options]="(operatorsScapi$ | async)" [labelAttr]="['username']" valueAttr="username"
                            [ngModel]="filters.assignedScapiUsername"
                            (ngModelChange)="setAssignedScapiUsername($event)"/>
        </div>

        <div class="label label-select">
          <span i18n>Tags</span><span *ngIf="filters.assessmentScapi.tags.length > 0">
                ({{ filters.assessmentScapi.tags.length }})</span>
          <div class="select is-fullwidth is-multiple">
            <select multiple [(ngModel)]="filters.assessmentScapi.tags">
              <ng-container *ngFor="let entry of tagTree">
                <option class="tag-category" disabled>{{ entry.block }}</option>
                <option *ngFor="let tag of entry.tags" [ngValue]="tag.name">&emsp;{{ tag.label }}</option>
              </ng-container>
            </select>
          </div>
        </div>

        <div class="label label-select">
          <span i18n>Type of plot</span><span *ngIf="filters.ncode.category.length > 0">
                ({{ filters.ncode.category.length }})</span>
          <div class="select is-fullwidth is-multiple">
            <select multiple [(ngModel)]="filters.ncode.category">
              <ng-container *ngIf="categories">
                <option *ngFor="let category of categories" [ngValue]="category.id">{{ category.id }}
                  : {{ category.label }}
                </option>
              </ng-container>
            </select>
          </div>
        </div>

        <div class="label label-select">
          <span i18n>Monitor</span><span *ngIf="filters.results.monitor.length > 0">
                ({{ filters.results.monitor.length }})</span>
          <div class="select is-fullwidth is-multiple">
            <select multiple [(ngModel)]="filters.results.monitor">
              <ng-container *ngIf="(monitors$ | async) as monitors">
                <option *ngFor="let monitor of monitors" [ngValue]="monitor.name">{{ monitor.internalName }}</option>
              </ng-container>
            </select>
          </div>
        </div>
      </div>

      <div class="is-flex is-flex-direction-row is-flex-wrap-wrap filter-row checkbox-row">
        <div class="label label-select" style="width: 350px">
          <ng-container i18n>From date</ng-container>
          <input class="input" type="date" [max]="today" [(ngModel)]="fromDate">
        </div>

        <div class="label label-select" style="width: 350px">
          <ng-container i18n>To date</ng-container>
          <input class="input" type="date" [max]="today" [(ngModel)]="toDate">
        </div>


        <div class="label label-select is-flex is-flex-direction-column">
          <ng-container i18n>Status</ng-container>
          <div class="select">
            <select [(ngModel)]="selectState">
              <option [ngValue]="null"></option>
              <option [ngValue]="'new'">
                <ng-container i18n>new</ng-container>
              </option>
              <option [ngValue]="'in_progress'">
                <ng-container i18n>saved only</ng-container>
              </option>
              <option [ngValue]="'finished'">
                <ng-container i18n>finished</ng-container>
              </option>
            </select>
          </div>
        </div>

        <div class="label label-select is-flex is-flex-direction-column">
          <ng-container i18n>Selected for sFB</ng-container>
          <div class="select">
            <select [(ngModel)]="checkSfb">
              <option [ngValue]="null"></option>
              <option [ngValue]="true">
                <ng-container i18n>Yes</ng-container>
              </option>
              <option [ngValue]="false">
                <ng-container i18n>No</ng-container>
              </option>
            </select>
          </div>
        </div>

        <div class="label label-select is-flex is-flex-direction-column">
          <ng-container i18n>Photo available</ng-container>
          <div class="select is-disabled">
            <select [(ngModel)]="checkPhoto" [disabled]="true">
              <option [ngValue]="null"></option>
              <option [ngValue]="true">
                <ng-container i18n>Yes</ng-container>
              </option>
              <option [ngValue]="false">
                <ng-container i18n>No</ng-container>
              </option>
            </select>
          </div>
        </div>

        <div class="label label-select is-flex is-flex-direction-column">
          <ng-container i18n>On-site photo requested</ng-container>
          <div class="select">
            <select [(ngModel)]="checkPhotoRequested">
              <option [ngValue]="null"></option>
              <option [ngValue]="true">
                <ng-container i18n>Yes</ng-container>
              </option>
              <option [ngValue]="false">
                <ng-container i18n>No</ng-container>
              </option>
            </select>
          </div>
        </div>

        <div class="label label-select is-flex is-flex-direction-column">
          <ng-container i18n>NCode-D &ne; NCode-M</ng-container>
          <div class="select">
            <select [(ngModel)]="checkNCodesMismatch">
              <option [ngValue]="null"></option>
              <option [ngValue]="true">
                <ng-container i18n>Yes</ng-container>
              </option>
              <option [ngValue]="false">
                <ng-container i18n>No</ng-container>
              </option>
            </select>
          </div>
        </div>

      </div>

      <div class="is-flex is-flex-direction-row is-flex-wrap-wrap filter-row unisized-labels"
           style="margin-bottom: 10px">
        <div class="label label-select">
          <ng-container i18n>Object IDs</ng-container>
          <input class="input" type="text" [ngModel]="objectIDsString" (ngModelChange)="setObjectIDsString($event)">
        </div>

        <div class="label label-select">
          <ng-container i18n>Result codes</ng-container>
          <input class="input" type="text" [ngModel]="resultCodesString"
                 (ngModelChange)="setResultCodesString($event)">
        </div>

        <div class="label label-select">
          <div class="is-flex is-align-items-center">
            <ng-container i18n>Comments</ng-container>&nbsp;
            <span class="icon is-small is-right is-clickable" (click)="showCommentsInfo($event)"><i-feather
              name="help-circle" class="icon-smaller"></i-feather></span>
          </div>

          <input class="input" type="text" [(ngModel)]="filters.assessmentScapi.comment">
        </div>
      </div>

      <button *ngIf="!loading; else loadingBtn" class="button is-primary" (click)="requestPlots()">
        <strong i18n>Apply filters</strong>
      </button>
      <ng-template #loadingBtn>
        <button class="button is-primary" disabled>
          <div class="loader"></div>&nbsp;
          <strong i18n>Apply filters</strong>
        </button>
      </ng-template>
      &nbsp;
      <button class="button" (click)="resetFilters()" [disabled]="loading">
        <ng-container i18n>Reset filters</ng-container>
      </button>
    </div>
  </div>

  <div class="dropdown" *ngIf="plots.length >= 1" [ngClass]="{'is-active': isFieldDropdownActive}">
    <div class="tags-wrapper">
      <div class="tags" (click)="showSelectableFields($event)">
          <span *ngFor="let field of selectedFields" class="tag is-rounded is-info is-light">
            {{ getFieldLabel(field) }}
            <button class="delete is-small" (click)="unselectField($event, field)"></button>
          </span>
      </div>
    </div>
    <div class="dropdown-menu" id="dropdown-menu" role="menu">
      <div class="dropdown-content">
        <a *ngFor="let field of unselectedFields" class="dropdown-item" (click)="selectField(field)">
          {{ getFieldLabel(field) }}
        </a>
      </div>
    </div>
  </div>

  <div class="table-container" *ngIf="plots.length >= 1"
       [ngClass]="{'navbar-visible': plots.length >= 1}">
    <app-plot-list-multiple [plots]="plots" [selectedPlotIds]="assignment.plotIds" [fields]="selectedFields"
                            [sortableColumns]="sortableFields" [showIndex]="true" (plotsSelected)="onPlotsSelected($event)"
                            (scrolled)="onScrolled()" (requestSorting)="setSorting($event)"></app-plot-list-multiple>
  </div>


  <nav class="navbar is-fixed-bottom" *ngIf="plots.length >= 1">
    <div class="navbar-start">
      <div class="navbar-item" *ngIf="featuresCount > 0">
        <div class="label">{{ featuresCount }}
          <ng-container i18n>Plots</ng-container>
        </div>
      </div>
      <div class="navbar-item" *ngIf="assignment.plotIds.length > 0">
        <div class="label">{{ assignment.plotIds.length }}
          <ng-container i18n>selected</ng-container>
        </div>
      </div>
      <div class="navbar-item">
        <div class="buttons">
          <button class="button" (click)="selectAll()">
            <ng-container i18n>Select all</ng-container>
          </button>
          <button class="button" (click)="selectNone()" [disabled]="assignment.plotIds.length === 0">
            <ng-container i18n>Select none</ng-container>
          </button>
          <button class="button" (click)="invertSelection()" [disabled]="assignment.plotIds.length === 0">
            <ng-container i18n>Invert selection</ng-container>
          </button>
          <button class="button" (click)="exportAsCSV()" [disabled]="assignment.plotIds.length === 0">
            <span class="icon">
              <i-feather name="download"></i-feather>
            </span>
            <span i18n>Export CSV</span>
          </button>
        </div>
      </div>
    </div>
    <div class="navbar-end">
      <div class="navbar-item">
        <div class="label" i18n>For</div>
      </div>
      <div class="navbar-item">
        <div class="select">
          <select [(ngModel)]="assignment.method">
            <option disabled selected [ngValue]="''"></option>
            <option value="sfb">sFB</option>
            <option value="scapi">SCAPI</option>
          </select>
        </div>
      </div>
      <div class="navbar-item">
        <div class="label" i18n>assign to</div>
      </div>
      <div class="navbar-item">
        <div class="select">
          <select [(ngModel)]="assignment.operator" [disabled]="!assignment.method">
            <option disabled selected [ngValue]="''"></option>
            <ng-container *ngIf="assignment.method === 'sfb'; else operatorsScapiTpl">
              <option *ngFor="let operator of (operatorsSfb$ | async)" [ngValue]="operator.username">
                {{ operator.username }}
              </option>
            </ng-container>
            <ng-template #operatorsScapiTpl>
              <ng-container *ngIf="assignment.method === 'scapi'">
                <option *ngFor="let operator of (operatorsScapi$ | async)" [ngValue]="operator.username">
                  {{ operator.username }}
                </option>
              </ng-container>
            </ng-template>
          </select>
        </div>
      </div>
      <div class="navbar-item" *ngIf="assignment.method === 'sfb'">
        <input class="input" type="text" placeholder="Map index" i18n-placeholder>
      </div>
      <div class="navbar-item" *ngIf="assignment.method === 'scapi'">
        <div class="label" i18n>on</div>
      </div>
      <div class="navbar-item" *ngIf="assignment.method === 'scapi'">
        <div class="select">
          <select [(ngModel)]="assignment.target">
            <option value="task-list">
              <ng-container i18n>Task list</ng-container>
            </option>
            <option value="watch-list">
              <ng-container i18n>Watch list</ng-container>
            </option>
          </select>
        </div>
      </div>
      <div class="navbar-item">
        <div class="buttons">
          <button class="button is-primary" [disabled]="!assignment.method || !assignment.operator"
                  (click)="assignOperator()">
            <strong>OK</strong>
          </button>
        </div>
      </div>
    </div>
  </nav>
</div>
