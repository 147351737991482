<ng-container *ngIf="componentPositions">
  <div *ngIf="!maximizedItem; else singleItem" class="grid" (mouseup)="endDrag()" (mousemove)="onDrag($event)" #grid>
    <div class="leftcol" #leftcol>
      <div id="left-top" class="grid-area left-top" dndDropzone (dndDrop)="onDrop($event, 'left-top')">
        <ng-container *ngTemplateOutlet="dashboardComp; context: {position: 'left-top'}"></ng-container>
      </div>
      <div class="dragbar left-center-dragbar" (mousedown)="startDragging('left-center')"></div>
      <div id="left-bottom" class="grid-area left-bottom" dndDropzone (dndDrop)="onDrop($event, 'left-bottom')">
        <ng-container *ngTemplateOutlet="dashboardComp; context: {position: 'left-bottom'}"></ng-container>
      </div>
    </div>
    <div class="dragbar left-dragbar" (mousedown)="startDragging('left')"></div>
    <div id="center" class="grid-area center" dndDropzone (dndDrop)="onDrop($event, 'center')">
      <ng-container *ngTemplateOutlet="dashboardComp; context: {position: 'center'}"></ng-container>
    </div>
    <div class="dragbar right-dragbar" (mousedown)="startDragging('right')"></div>
    <div class="rightcol" #rightcol>
      <div id="right-top" class="grid-area right-top" dndDropzone (dndDrop)="onDrop($event, 'right-top')">
        <ng-container *ngTemplateOutlet="dashboardComp; context: {position: 'right-top'}"></ng-container>
      </div>
      <div class="dragbar right-center-dragbar" (mousedown)="startDragging('right-center')"></div>
      <div id="right-bottom" class="grid-area right-bottom" dndDropzone (dndDrop)="onDrop($event, 'right-bottom')">
        <ng-container *ngTemplateOutlet="dashboardComp; context: {position: 'right-bottom'}"></ng-container>
      </div>
    </div>
    <div class="dragbar center-bottom-dragbar" (mousedown)="startDragging('center-bottom')"></div>
    <div class="center-bottom" #centerbottom>
      <div id="bottom-left" class="grid-area bottom-left" dndDropzone (dndDrop)="onDrop($event, 'bottom-left')">
        <ng-container *ngTemplateOutlet="dashboardComp; context: {position: 'bottom-left'}"></ng-container>
      </div>
      <div class="dragbar bottom-center-dragbar" (mousedown)="startDragging('bottom-center')"></div>
      <div id="bottom-right" class="grid-area bottom-right" dndDropzone (dndDrop)="onDrop($event, 'bottom-right')">
        <ng-container *ngTemplateOutlet="dashboardComp; context: {position: 'bottom-right'}"></ng-container>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #dashboardComp let-position="position">
  <ng-container class="single-item-view" [ngSwitch]="componentPositions[position]">
    <ng-container *ngSwitchCase="'chart'" [ngTemplateOutlet]="chart"></ng-container>
    <ng-container *ngSwitchCase="'details'" [ngTemplateOutlet]="details"></ng-container>
    <ng-container *ngSwitchCase="'map'" [ngTemplateOutlet]="map"></ng-container>
    <ng-container *ngSwitchCase="'table'" [ngTemplateOutlet]="table"></ng-container>
    <ng-container *ngSwitchCase="'editor'" [ngTemplateOutlet]="editor"></ng-container>
    <ng-container *ngSwitchCase="'events'" [ngTemplateOutlet]="events"></ng-container>
    <ng-container *ngSwitchCase="'gallery'" [ngTemplateOutlet]="gallery"></ng-container>
  </ng-container>
</ng-template>

<ng-template #singleItem>
  <div class="single-item-view" [ngSwitch]="maximizedItem">
    <ng-container *ngSwitchCase="'chart'" [ngTemplateOutlet]="chart"></ng-container>
    <ng-container *ngSwitchCase="'details'" [ngTemplateOutlet]="details"></ng-container>
    <ng-container *ngSwitchCase="'map'" [ngTemplateOutlet]="map"></ng-container>
    <ng-container *ngSwitchCase="'table'" [ngTemplateOutlet]="table"></ng-container>
    <ng-container *ngSwitchCase="'editor'" [ngTemplateOutlet]="editor"></ng-container>
    <ng-container *ngSwitchCase="'events'" [ngTemplateOutlet]="events"></ng-container>
    <ng-container *ngSwitchCase="'gallery'" [ngTemplateOutlet]="gallery"></ng-container>
  </div>
</ng-template>

<ng-template #chart>
  <app-dashboard-item id="chart" dndDraggable="chart" dndEffectAllowed="move" [dndDragImageOffsetFunction]="getDragImageOffset" (toggleMaximize)="toggleMaximizeItem('chart')" [maximized]="maximizedItem === 'chart'">
    <app-chart></app-chart>
  </app-dashboard-item>
</ng-template>

<ng-template #details>
  <app-dashboard-item id="details" dndDraggable="details" dndEffectAllowed="move" [dndDragImageOffsetFunction]="getDragImageOffset" (toggleMaximize)="toggleMaximizeItem('details')" [maximized]="maximizedItem === 'details'">
    <app-plot-details [ngClass]="{'no-selection': selectedPlotIds.length === 0}"></app-plot-details>
  </app-dashboard-item>
</ng-template>

<ng-template #editor>
  <app-dashboard-item id="editor" dndDraggable="editor" dndEffectAllowed="move" [dndDragImageOffsetFunction]="getDragImageOffset" (toggleMaximize)="toggleMaximizeItem('editor')" [maximized]="maximizedItem === 'editor'">
    <app-editor [ngClass]="{'no-selection': selectedPlotIds.length === 0}"></app-editor>
  </app-dashboard-item>
</ng-template>

<ng-template #events>
  <app-dashboard-item id="events" dndDraggable="events" dndEffectAllowed="move" [dndDragImageOffsetFunction]="getDragImageOffset" (toggleMaximize)="toggleMaximizeItem('events')" [maximized]="maximizedItem === 'events'">
    <app-events [ngClass]="{'no-selection': selectedPlotIds.length === 0}"></app-events>
  </app-dashboard-item>
</ng-template>

<ng-template #map>
  <app-dashboard-item id="map" dndDraggable="map" dndEffectAllowed="move" [dndDragImageOffsetFunction]="getDragImageOffset" (toggleMaximize)="toggleMaximizeItem('map')" [maximized]="maximizedItem === 'map'">
    <app-map></app-map>
  </app-dashboard-item>
</ng-template>

<ng-template #table>
  <app-dashboard-item id="table" dndDraggable="table" dndEffectAllowed="move" [dndDragImageOffsetFunction]="getDragImageOffset" (toggleMaximize)="toggleMaximizeItem('table')" [maximized]="maximizedItem === 'table'">
    <app-plots></app-plots>
  </app-dashboard-item>
</ng-template>

<ng-template #gallery>
  <app-dashboard-item id="gallery" dndDraggable="gallery" dndEffectAllowed="move" [dndDragImageOffsetFunction]="getDragImageOffset" (toggleMaximize)="toggleMaximizeItem('gallery')" [maximized]="maximizedItem === 'gallery'">
    <app-image-gallery></app-image-gallery>
  </app-dashboard-item>
</ng-template>




