export const environment = {
  production: false,
  name: 'stage',
  well_known_url: 'https://agrosurvey-staging.eftas.services/.well-known/ams',
  keycloak_url: 'https://auth.agrosurvey-staging.eftas.services',
  keycloak_realm: 'ams',
  keycloak_clientId: 'agrosurvey_amsdashboard',
  keycloak_redirectUri: 'https://agrosurvey-staging.eftas.services',
  holiday_special: true
}
