import {Component} from '@angular/core';
import {StatisticsComponent} from "../statistics/statistics.component";
import {AdminChartsComponent} from '../admin-charts/admin-charts.component';
import {ScapiAssignmentComponent} from "../scapi-assignment/scapi-assignment.component";
import {SfbAssignmentComponent} from "../sfb-assignment/sfb-assignment.component";
import {CommonModule} from "@angular/common";
import {NavigationEnd, Router, RouterLink, RouterOutlet} from "@angular/router";
import {filter} from "rxjs";

@Component({
  standalone: true,
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  imports: [
    CommonModule,
    AdminChartsComponent,
    StatisticsComponent,
    ScapiAssignmentComponent,
    SfbAssignmentComponent,
    RouterOutlet,
    RouterLink
  ],
  styleUrls: ['./administration.component.scss']
})
export class AdministrationComponent {

  activeTab: 'scapi-zuweisung' | 'sfb-zuweisung' | 'statistik' | 'admin-charts' = 'scapi-zuweisung';

  constructor(
    private router: Router
  ) {
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe(event => this.setActiveTab(event.url));
  }

  private setActiveTab(url: string | undefined) {
    if (url) {
      url = url.split("?")[0];
      url = url.split("/").pop();
      if (url && ['scapi-zuweisung', 'sfb-zuweisung', 'statistik', 'admin-charts'].indexOf(url) !== -1) {
        this.activeTab = url as 'scapi-zuweisung' | 'sfb-zuweisung' | 'statistik' | 'admin-charts';
      }
    }
  }
}
