import {AfterViewInit, Directive, ElementRef, EventEmitter, Input, Output} from '@angular/core';
import {Swiper, SwiperOptions} from "swiper/types";

@Directive({
  selector: '[appSwiper]',
  standalone: true
})
/**
 * The SwiperDirective is a replacement for the ion-slides component which was removed in v7 of ionic in favor of
 * swiper.js
 */
export class SwiperDirective implements AfterViewInit {

  private readonly swiperElement: HTMLElement;

  /**
   * SwiperOptions for the Swiper element
   */
  @Input('config') config?: SwiperOptions;

  /**
   * Event that communicates when a slide was changed
   */
  @Output() slideChange: EventEmitter<Swiper> = new EventEmitter();

  constructor(private el: ElementRef<HTMLElement>) {
    this.swiperElement = el.nativeElement;
  }

  ngAfterViewInit() {
    Object.assign(this.el.nativeElement, this.config);

    // @ts-ignore
    this.el.nativeElement.initialize();

    // connect the swipers swiperslidechangetransitionend event to the slideChange output event
    this.el.nativeElement.addEventListener('swiperslidechangetransitionend', (event: any) => {
      const swiper: Swiper = event.target.swiper;
      this.slideChange.emit(swiper);
    });
  }

  /**
   * Run transition to the slide with index number equal to 'index' parameter for the
   *  duration equal to 'speed' parameter.
   *
   * @param index Index number of slide.
   * @param speed Transition duration (in ms).
   * @param runCallbacks Set it to false (by default it is true) and transition will
   *  not produce transition events.
   */
  slideTo(index: number, speed?: number, runCallbacks?: boolean) {
    // @ts-ignore
    this.swiperElement.swiper.slideTo(index, speed, runCallbacks);
  }

}
