import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {PlotsService} from "../../services/plots.service";
import {PlotEvent, PlotMonitors} from "../../model/plot";
import {Subscription, firstValueFrom} from "rxjs";
import {LabeledFieldComponent} from "../labeled-field/labeled-field.component";
import {CommonModule} from "@angular/common";
import {Monitor, EventMowingUpdateInput, Assessment} from "../../model/assessment";
import {eventsUpdate, selectedPlotsChange, activeAssessmentStatusChange, ActiveAssessmentChangeParams} from '../../events';

@Component({
  standalone: true,
  selector: 'app-events',
  templateUrl: './events.component.html',
  imports: [
    CommonModule,
    FormsModule,
    LabeledFieldComponent
  ],
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit, OnDestroy {

  events: PlotEvent[] | undefined;
  currentPlotIds: number[] = [];
  sorting: { column: string; direction: 'asc' | 'desc' } | null = null;
  lockout: Monitor | undefined;
  plotMonitors: PlotMonitors | undefined;

  subscription: Subscription | undefined;
  updateEventSubscription: Subscription | undefined;

  activeAssessmentSubscription: Subscription | undefined;
  activeAssessmentStatus: boolean | undefined;

  constructor(private plotsService: PlotsService) {
  }

  async ngOnInit() {
    this.subscription = selectedPlotsChange.subscribe(async data => {
      this.currentPlotIds = data.plotIds;
      if (data.plotIds.length >= 1) {
        const assessments = await firstValueFrom(this.plotsService.getScapiAssessments(data.plotIds[0]));
        this.activeAssessmentStatus = assessments &&assessments.length > 0 ? assessments[assessments.length - 1].finished : false;
        console.log('Events: set active assessment status', this.activeAssessmentStatus);
        this.events = await firstValueFrom(this.plotsService.getPlotEvents(data.plotIds[0]));
        this.plotMonitors = await firstValueFrom(this.plotsService.getPlotMonitors(data.plotIds[0]));
        const currentYear = this.events[0]?.date ? this.events[0].date.getFullYear() : 2024;

        this.lockout = await firstValueFrom(this.plotsService.getMonitor('SPERR_BRA'));
        const startParts = this.lockout.dateStart.split(".");
        const endParts = this.lockout.dateEnd.split(".");
        this.lockout.dateStartParsed = new Date(currentYear, Number(startParts[1]) - 1, Number(startParts[0]));
        this.lockout.dateEndParsed = new Date(currentYear, Number(endParts[1]) - 1, Number(endParts[0]));
        console.log('lockout', this.lockout);
      } else {
        this.events = undefined;
        this.lockout = undefined;
      }
    });
    
    this.activeAssessmentSubscription = activeAssessmentStatusChange.subscribe(async data => {
      console.log('Events: on active assessment change', data);
      this.activeAssessmentStatus = data.finished;
    });

  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  sortBy(column: 'type' | 'date' | 'source') {
    const direction = this.sorting && this.sorting.column === column && this.sorting.direction === 'asc' ? 'desc' : 'asc';

    this.events?.sort((a: PlotEvent, b: PlotEvent) => {
      let value: number = 0;
      if (column === 'type' || column === 'source') {
        const columnA = a[column] ? a[column] as string : "";
        const columnB = b[column] ? b[column] as string : "";
        if (columnA < columnB) {
          value = -1;
        } else if (columnA > columnB) {
          value = 1;
        }
      } else {
        const columnA = a[column] ? (a[column] as Date).valueOf() : 0;
        const columnB = b[column] ? (b[column] as Date).valueOf() : 0;
        value = columnA - columnB;
      }

      if (direction === 'desc') {
        value *= -1;
      }

      this.sorting = {column, direction};

      return value;
    });
  }

  checkDate(date: Date | undefined) {
    if (date && this.lockout?.dateEndParsed && this.lockout?.dateStartParsed) {
      return (date >= this.lockout.dateStartParsed && date <= this.lockout.dateEndParsed);
    } else {
      return false;
    }
  }

  findSperrBra() {
    if (this.plotMonitors && this.plotMonitors.finalResults.length > 0) {
      const sperrBra = this.plotMonitors.finalResults.find(item => item.monitor === 'SPERR_BRA');
      return sperrBra;
    } else {
      return false;
    }
  }

  onValidChange(event: PlotEvent) {
    this.updateEventSubscription?.unsubscribe();
    const eventUpdate: EventMowingUpdateInput = {
      date: event.date.toISOString().substring(0, 10),
      featureId: event.featureId,
      source: event.source,
      valid: event.valid
    };

    this.updateEventSubscription = this.plotsService.updateEvent(eventUpdate)
      .subscribe(async () => {
        if (this.currentPlotIds.length > 0) {
          this.events = await firstValueFrom(this.plotsService.getPlotEvents(this.currentPlotIds[0]));
          eventsUpdate.next({plotIds: this.currentPlotIds});
        }
      });
  }
}
