<div class="message is-fullwidth">

  <div class="message-header" (dblclick)="toggleMinimizeFilters()">
    <p i18n>Filters</p>
    <button (click)="toggleMinimizeFilters()">
          <span class="icon is-small">
            <i-feather *ngIf="!filtersMinimized; else minimized" name="chevron-up"></i-feather>
            <ng-template #minimized>
              <i-feather name="chevron-down"></i-feather>
            </ng-template>
          </span>
    </button>
  </div>

  <div *ngIf="!filtersMinimized" class="message-body">
    <div class="columns">
      <div class="column">
        <div class="label label-select">
          <span i18n>Type of statistic</span>
          <div class="select is-fullwidth is-multiple">
            <select multiple [(ngModel)]="statisticFilters.type" (change)='multiToMonoSelectStatistic()'>
              <ng-container *ngIf="statisticTypes">
                <option *ngFor="let type of statisticTypes" [ngValue]="type.id">
                  {{ type.label }}
                </option>
              </ng-container>
            </select>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="label label-select">
          <span i18n>NCode-D</span><span *ngIf="statisticFilters.ncode.code.length > 0">
                ({{ statisticFilters.ncode.code.length }})</span>
          <app-combo-select *ngIf="(nCodes$ | async) as nCodes" [options]="nCodes"
                            [labelAttr]="['code', 'codeLabel']" valueAttr="code"
                            [(ngModel)]="statisticFilters.ncode.code"
                            [disabled]="true || statisticFilters.type.length === 0 || statisticFilters.type[0].startsWith('user')"/>
        </div>
      </div>
      <div class="column">
        <div class="label label-select">
          <span i18n>Class</span><span *ngIf="statisticFilters.ncode.classCode.length > 0">
                ({{ statisticFilters.ncode.classCode.length }})</span>
          <app-combo-select *ngIf="(classes$ | async) as classes" [options]="classes"
                            [labelAttr]="['code', 'label']" valueAttr="code"
                            [(ngModel)]="statisticFilters.ncode.classCode"
                            [disabled]="true || statisticFilters.type.length === 0 || statisticFilters.type[0].startsWith('user')"/>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column"></div>
      <div class="column">
        <div class="label label-select">
          <span i18n>Monitor</span><span *ngIf="statisticFilters.results.monitor.length > 0">
                ({{ statisticFilters.results.monitor.length }})</span>
          <div class="select is-fullwidth is-multiple">
            <select multiple [(ngModel)]="statisticFilters.results.monitor"
                    [disabled]="true || statisticFilters.type.length === 0 || statisticFilters.type[0] !== 'final_result'">
              <ng-container *ngIf="(monitors$ | async) as monitors">
                <option *ngFor="let monitor of monitors" [ngValue]="monitor.name">{{ monitor.internalName }}</option>
              </ng-container>
            </select>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="label label-select">
          <div class="control">
            <ng-container i18n>On sCAPI</ng-container>&nbsp;
            <span *ngIf="statisticFilters.assignedScapiUsername.length > 0">
                  ({{ statisticFilters.assignedScapiUsername.length }})</span>
          </div>
          <app-combo-select [options]="(operatorsScapi$ | async)" [labelAttr]="['username']" valueAttr="username"
                            [(ngModel)]="statisticFilters.assignedScapiUsername"
                            [disabled]="statisticFilters.type.length === 0 || !statisticFilters.type[0].startsWith('user')"/>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="checkbox-row">

        <div class="label label-select is-flex is-flex-direction-column">
          <ng-container i18n>sCAPI state</ng-container>
          <div class="select">
            <select [(ngModel)]="statisticFilters.scapiState"
                    [disabled]="true || statisticFilters.type.length === 0 ||  statisticFilters.type === 'user_history'">
              <ng-container *ngIf="scapiStates">
                <option *ngFor="let state of scapiStates" [ngValue]="state.id">
                  {{ state.label }}
                </option>
              </ng-container>
            </select>
          </div>
        </div>

        <div class="label label-select is-flex is-flex-direction-column">
          <ng-container i18n>Selected for sFB</ng-container>
          <div class="select">
            <select [(ngModel)]="checkSfb"
                    [disabled]="true || statisticFilters.type.length === 0 ||  statisticFilters.type[0].startsWith('user')">
              <option [value]="null"></option>
              <option [value]="true">
                <ng-container i18n>Yes</ng-container>
              </option>
              <option [value]="false">
                <ng-container i18n>No</ng-container>
              </option>
            </select>
          </div>
        </div>

        <div class="label label-select is-flex is-flex-direction-column">
          <ng-container i18n>Date from</ng-container>
          <input [(ngModel)]="statisticFilters.date.from" class="input" type="date"
                 [disabled]="statisticFilters.type.length === 0 || statisticFilters.type[0] !== 'user_history'">
        </div>

        <div class="label label-select is-flex is-flex-direction-column">
          <ng-container i18n>Date to</ng-container>
          <input [(ngModel)]="statisticFilters.date.to" class="input" type="date"
                 [disabled]="statisticFilters.type.length === 0 || statisticFilters.type[0] !== 'user_history'">
        </div>
      </div>
    </div>
    <button *ngIf="!loadingStatistics; else loadingBtnStatistics" class="button is-primary"
            (click)="requestStatistics()" [disabled]="statisticFilters.type.length === 0">
      <strong i18n>Apply filters</strong>
    </button>
    <ng-template #loadingBtnStatistics>
      <button class="button is-primary" disabled>
        <div class="loader"></div>&nbsp;
        <strong i18n>Apply filters</strong>
      </button>
    </ng-template>
    &nbsp;
    <button class="button" (click)="resetStatisticsFilters()" [disabled]="loading">
      <ng-container i18n>Reset filters</ng-container>
    </button>
  </div>
</div>


<div class="table-container" id="stats-table" *ngIf="statistics && statistics.length > 0"
     [ngClass]="{'navbar-visible': statistics.length >= 1}">
  <button class="button is-light is-small"
          (click)="saveStatistics()">
        <span class="icon is-small">
          <i-feather name="save" class="icon-smaller"></i-feather>
        </span>
  </button>
  <table class="table is-narrow is-hoverable is-fullwidth table-compact">
    <thead>
    <tr>
      <th *ngFor="let column of statColumns" [ngClass]="getColumnNameClass(column.real)"
          (click)="statisticSortBy(column.real)" class="has-text-centered">
        <div class="cell-wrapper is-align-items-center">
          <ng-container *ngIf="statisticSorting && statisticSorting.field === column.real">
            <i-feather class="sort-direction-indicator" *ngIf="statisticSorting.direction === 'ASC'; else desc"
                       name="chevron-up"></i-feather>
            <ng-template #desc>
              <i-feather class="sort-direction-indicator" name="chevron-down"></i-feather>
            </ng-template>
          </ng-container>
          {{ column.readable }}
        </div>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let stat of statistics" [ngClass]="getRowClass(stat['code'], stat['date'], stat['user'])">
      <td *ngFor="let column of statColumns" [ngClass]="getColumnClass(column.real)" class="has-text-centered">
        {{ stat[column.real] }}
      </td>
    </tr>
    </tbody>
  </table>
</div>
