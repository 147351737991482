<div class="control has-icons-left has-icons-right">
  <input class="input is-small input-search" type="text" placeholder="Search" i18n-placeholder="Search"
    (change)="onSearchInputChange($event)">
  <span class="icon is-small is-left">
    <i-feather name="search" class="icon-smaller"></i-feather>
  </span>
  <span class="icon is-small is-right is-clickable" style="margin-right: 24px" (click)="toggleVisiblePlotsChooser()">
    <i-feather name="settings" class="icon-smaller"></i-feather>
  </span>
  <span class="icon is-small is-right is-clickable" (click)="showSearchInfo($event)">
    <i-feather name="help-circle" class="icon-smaller"></i-feather>
  </span>
</div>

<div *ngIf="showSettings" class="is-flex plots-chooser">
  <label class="radio">
    <input type="radio" name="plotStatesShown" [(ngModel)]="plotStatesShown" value="all" (change)="onPlotStatesShownChange()">
    alle ({{plotsCount?.total}})
  </label>
  <label class="radio">
    <input type="radio" name="plotStatesShown" [(ngModel)]="plotStatesShown" value="open" (change)="onPlotStatesShownChange()">
    unbearbeitet ({{plotsCount?.open}})
  </label>
  <label class="radio">
    <input type="radio" name="plotStatesShown" [(ngModel)]="plotStatesShown" value="edited" (change)="onPlotStatesShownChange()">
    bearbeitet ({{plotsCount?.edited}})
  </label>
  <label class="radio">
    <input type="radio" name="plotStatesShown" [(ngModel)]="plotStatesShown" value="closed" (change)="onPlotStatesShownChange()">
    abgeschlossen ({{plotsCount?.closed}})
  </label>
</div>

<div class="scroll-wrapper" infiniteScroll (scrolled)="onScroll()" [scrollWindow]="false" [ngStyle]="{'max-height': maxHeight}">
  <table class="table is-narrow is-hoverable is-fullwidth table-compact">
    <thead>
      <tr>
        <th *ngIf="showIndex" class="has-text-right">#</th>
        <th *ngFor="let column of columns" (click)="sortBy(column)">
          <div class="cell-wrapper is-flex is-flex-direction-row is-align-items-center"
            [ngClass]="[getColumnType(column) === 'number' ? 'is-justify-content-flex-end' : 'is-justify-content-flex-start']">
            <ng-container *ngIf="sorting && sorting.field === column">
              <i-feather class="sort-direction-indicator" *ngIf="sorting.direction === 'ASC'; else desc" name="chevron-up"></i-feather>
              <ng-template #desc>
                <i-feather class="sort-direction-indicator" name="chevron-down"></i-feather>
              </ng-template>
            </ng-container>
            {{columnLabels[column]}}
          </div>
        </th>
      </tr>
    </thead>
    <tbody >
      <ng-container *ngFor="let plot of filteredPlots; let i = index">
        <tr (click)="plotSelected.emit(plot.id)"
            class="state-{{plot.state}}" [ngClass]="{'is-selected': selectedPlotIds && selectedPlotIds.indexOf(plot.id) !== -1}">
          <td *ngIf="showIndex" class="has-text-right">{{i}}</td>
          <td *ngFor="let column of columns"
              [ngClass]="[getColumnType(column) === 'number' ? 'has-text-right' : 'has-text-left']">
            {{plot.getAsString(column)}}
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>

<!--<app-visible-plots-chooser *ngIf="showSettings" [statesShown]="plotStatesShown" (closeMessage)="hideVisiblePlotsChooser($event)"></app-visible-plots-chooser>-->
