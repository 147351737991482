import {ReplaySubject, Subject} from 'rxjs';
import {SafeHtml} from '@angular/platform-browser';
import {State} from './model';

/*
 * This file contains globally used Subjects
 */

/**
 * Used to set the base url to the ams server depending on the chosen state
 */
export const amsUrl: ReplaySubject<string> = new ReplaySubject<string>(1);

/**
 * Used to set the url to the photo server depending on the chosen state
 */
export const amsPhotoUrl: ReplaySubject<string> = new ReplaySubject<string>(1);

/**
 * Used to signal a change in the dashboard's size
 */
export const dashboardResized: Subject<void> = new Subject();

/**
 * Payload for the eventsUpdate Subject
 *
 * @property {number[]} plotIds - Ids of plots with updated events
 */
export interface EventsUpdateParams {
  plotIds: number[];
}

/**
 * Triggered when there are changes in plot events
 */
export const eventsUpdate: Subject<EventsUpdateParams> = new Subject();

/**
 * Payload for the gridTemplatesChanged ReplaySubject
 *
 * @property {any} grid - The grid's DOM element
 * @property {any} rightcol - The DOM element of the grid's rightcol area
 * @property {any} centerbottom - The DOM element of the grid's centerbottom area
 * @property {any} leftcol - The DOM element of the grid's leftcol area
 */
export interface GridTemplatesChangedParams {
  grid: any;
  rightcol: any;
  centerbottom: any;
  leftcol: any;
}

/**
 * Triggered when the user changes the layout of the dashboard's grid
 */
export const gridTemplatesChanged: ReplaySubject<GridTemplatesChangedParams> = new ReplaySubject();

/**
 * Triggered when the user selects a plot image
 */
export const plotImageSelected: ReplaySubject<any> = new ReplaySubject();

export const requestRefreshPlot: Subject<{
  plotId: number;
  operation: 'add' | 'remove' | 'update';
  watchlist?: boolean
}> = new Subject();

/**
 * Used to reset the dashboard's layout to default values
 */
export const requestResetDashboardLayout: Subject<void> = new Subject();

/**
 * Payload for the selectedPhotoChange ReplaySubject
 *
 * @property {lat: number; lon: number; srid: number} geom - The photos geo position
 * @property {number} angle - Viewing angle
 * @property {boolean} detail
 */
export interface SelectedPhotoChangeParams {
  geom: {
    lat: number,
    lon: number,
    srid: number
  },
  angle: number,
  detail: boolean
}

/**
 * Triggered when the user selects a photo
 */
export const selectedPhotoChange: ReplaySubject<SelectedPhotoChangeParams | null> = new ReplaySubject();

/**
 * Payload for the selectedPlotsChange ReplaySubject
 *
 * @property {number[]} plotIds - Array of plot IDs that changed.
 * @property {string} origin - The origin or source of the change.
 */
export interface SelectedPlotsChangeParams {
  plotIds: number[];
  origin: string;
}

/**
 * Triggered whenever a plot is selected in the dashboard's plot list. Communicates the change to other components e.g.
 * map or chart.
 */
export const selectedPlotsChange: ReplaySubject<SelectedPlotsChangeParams> = new ReplaySubject(1);

/**
 * Payload for the activeAssessmentStatusChange ReplaySubject
 *
 * @property {boolean} finished - Whether the assessment is active or not
 */
export interface ActiveAssessmentChangeParams {
  finished: boolean;
}

/**
 * Triggered whenever an assessment is finished or a new one is created.
 */
export const activeAssessmentStatusChange: ReplaySubject<ActiveAssessmentChangeParams> = new ReplaySubject(1);

/**
 * Payload for the showNotification Subject
 *
 * @property {string} title the title of the notification
 * @property {string} message the notification body
 * @property {string} class extra css classes to add to the notification (.is-info, .is-warning)
 */
export interface ShowNotificationParams {
  title: string;
  message: string | SafeHtml;
  class?: string
}

/**
 * This subject can be triggered to create a notification
 */
export const showNotification: ReplaySubject<ShowNotificationParams | null> = new ReplaySubject(1);

/**
 * Used to set the app wide state (e.g. NW, NI, SH...)
 */
export const stateSet: ReplaySubject<State> = new ReplaySubject();

export const userInactive: Subject<boolean> = new Subject();
export const userTimeLeft: Subject<number> = new Subject();
