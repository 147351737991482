import {Component, OnDestroy, OnInit} from '@angular/core';

import Map from 'ol/Map.js';
import TileLayer from 'ol/layer/Tile.js';
import View from 'ol/View.js';
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import {GeoJSON} from "ol/format";
import {Fill, Stroke, Style, Icon} from "ol/style";
import {Extent, extend} from 'ol/extent';
import {ProjectionLike} from "ol/proj";
import {Geometry} from "ol/geom";
import proj4 from "proj4";
import {projections, wmsLayers} from "../../constants";
import {PlotsService} from "../../services/plots.service";
import {Polygon, Point} from "geojson";
import {Feature} from "ol";
import {register} from "ol/proj/proj4";
import {Layer} from "ol/layer";
import {Subscription} from "rxjs";
import {TileWMS} from "ol/source";
import {selectedPhotoChange, showNotification, selectedPlotsChange, stateSet} from '../../events';
import {FeatureLike} from "ol/Feature";

@Component({
  standalone: true,
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, OnDestroy {
  map: Map | undefined;
  selectedPlotIds: number[] = [];

  baseLayer: TileLayer<TileWMS> | undefined;

  plotLayer: Layer | undefined;
  locationLayer: Layer | undefined;
  polygonExtent: Extent | undefined;
  locationExtent: Extent | undefined;

  plotChangeSubscription: Subscription | undefined;
  getPlotGeomSubscription: Subscription | undefined;
  photoChangeSubscription: Subscription | undefined;
  stateChangedSunscription: Subscription | undefined;

  constructor(private plotsService: PlotsService) {
    proj4.defs(projections.utm32n, "+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs");
    register(proj4);
  }

  ngOnInit() {
    const tileLayers: TileLayer<TileWMS>[] = [];

    // wmsLayers.forEach(wmsInfo => {
    //   tileLayers.push(new TileLayer({
    //     source: new TileWMS({
    //       url: wmsInfo.baseUrl,
    //       params: {'LAYERS': wmsInfo.layer, 'TILES': true},
    //       attributions: wmsInfo.attribution + ` (<a href="${wmsInfo.licenceUrl}" target="_blank">${wmsInfo.licence}</a>)`
    //     })
    //   }));
    // });

    this.map = new Map({
      target: 'olMap',
      view: new View({
        projection: projections.webMercator,
        center: [1113195, 6710219],
        zoom: 4
      }),
    });

    this.stateChangedSunscription = stateSet.subscribe(state => {
      const layerInfo = wmsLayers.find(l => l.state === state.id);
      if (layerInfo) {
        if (this.baseLayer) {
          this.map?.removeLayer(this.baseLayer);
        }
        this.baseLayer = new TileLayer({
          source: new TileWMS({
            url: layerInfo.baseUrl,
            params: {'LAYERS': layerInfo.layer, 'TILES': true},
            attributions: layerInfo.attribution + ` (<a href="${layerInfo.licenceUrl}" target="_blank">${layerInfo.licence}</a>)`
          })
        });
        this.map?.addLayer(this.baseLayer);
        this.map?.getView().fit(state.extent);
      }
    });

    this.plotChangeSubscription = selectedPlotsChange.subscribe(data => {
      this.locationExtent = undefined;
      this.removePlot();

      if (data.plotIds.length >= 1) {
        this.selectedPlotIds = data.plotIds;
        this.getPlotGeomSubscription = this.plotsService.getPlotGeom(this.selectedPlotIds[0]).subscribe({
          next: geom => {
            this.addPlot(geom);
            this.getPlotGeomSubscription?.unsubscribe();
          },
          error: (e) => {
            console.error(e);
            // showNotification.next({title: 'Error', message: `2 No plot found for ID ${data.plotIds[0]}.`});
          }
        });
      } else {
        this.getPlotGeomSubscription?.unsubscribe();
      }
    });

    this.photoChangeSubscription = selectedPhotoChange.subscribe((location: {
      geom: {
        lat: number,
        lon: number,
        srid: number
      }, angle: number, detail: boolean
    } | null ) => {
      console.log('photo change subscription', location);
      this.removeLocation();

      if (location) {
        const geom: Point = {
          type: 'Point',
          coordinates: [location.geom.lon, location.geom.lat]
        }
        this.addLocation(geom, location.angle, location.detail);
      }
    });
  }

  ngOnDestroy() {
    this.plotChangeSubscription?.unsubscribe();
    this.getPlotGeomSubscription?.unsubscribe();
    this.photoChangeSubscription?.unsubscribe();
    this.stateChangedSunscription?.unsubscribe();
  }

  private addPlot(geom: Polygon) {
    const feature = new Feature(this.reprojectGeom(geom, projections.utm32n, projections.webMercator))

    const source = new VectorSource({features: [feature]});
    const style = new Style({
      stroke: new Stroke({
        color: 'rgba(255, 255, 0, 0.8)',
        lineDash: [4],
        width: 2,
      }),
      fill: new Fill({
        color: 'rgba(255, 255, 0, 0.1)',
      }),
    });
    this.plotLayer = new VectorLayer({source, style});
    this.map?.addLayer(this.plotLayer);
    const extent = source.getExtent();
    this.polygonExtent = JSON.parse(JSON.stringify(extent));
    if (this.polygonExtent) {
      if (this.locationExtent) {
        this.map?.getView().fit(extend(extent, this.locationExtent), {padding: [35, 35, 35, 35]});
      } else {
        this.map?.getView().fit(this.polygonExtent, {padding: [25, 25, 25, 25]});
      }
    }
  }

  private async addLocation(geom: Point, angle: number, detail: boolean) {
    console.log('would add location', geom, angle);
    const directionMarker = (angle != null && !detail);
    const feature = new Feature(this.reprojectGeom(geom, projections.wgs84, projections.webMercator))
    const source = new VectorSource({features: [feature]});
    const rotation = directionMarker ? angle * (Math.PI / 180) : 0;
    console.log('Rotation', rotation, angle);

    const iconPath = directionMarker ? 'assets/img/icons8-navigate-36.png' : 'assets/img/red-marker-icon.png';
    const anchor = directionMarker ? undefined : [0.5, 41];
    const icon = new Icon({
      anchor: anchor,
      anchorXUnits: 'fraction',
      anchorYUnits: 'pixels',
      src: iconPath,
    });

    icon.setRotation(rotation)
    const style = new Style({
      image: icon
    });

    this.locationLayer = new VectorLayer({source, style});
    this.map?.addLayer(this.locationLayer);

    // center map on both features
    const extent = source.getExtent()
    this.locationExtent = JSON.parse(JSON.stringify(extent));
    if (extent && this.polygonExtent) {
      this.map?.getView().fit(extend(extent, this.polygonExtent), {padding: [35, 35, 35, 35]});
    }
  }

  private reprojectGeom(geom: any, from: ProjectionLike, to: ProjectionLike): Geometry | undefined {
    let geometry: Geometry | undefined;
    const feature: FeatureLike = new GeoJSON().readFeature({
      type: 'Feature',
      geometry: geom,
      properties: {}
    }) as FeatureLike;
    // @ts-ignore
    geometry = feature.getGeometry();
    if (geometry) {
      const transformed = geometry.clone().transform(from, to);
      console.log('transformed geom', transformed);
      return geometry.clone().transform(from, to);
    } else {
      return undefined
    }
  }

  private removePlot() {
    if (this.plotLayer) {
      this.map?.removeLayer(this.plotLayer);
    }
  }

  private removeLocation() {
    if (this.locationLayer) {
      this.map?.removeLayer(this.locationLayer);
    }
  }
}
