<button class="drag-handle-button" dndHandle>
  <i-feather name="move"></i-feather>
</button>
<button class="toggle-maximize-button" (click)="requestToggleMaximize()">
  <i-feather *ngIf="!maximized; else minimized" name="maximize"></i-feather>
  <ng-template #minimized>
    <i-feather name="minimize"></i-feather>
  </ng-template>
</button>

<!-- here be content -->
<ng-content></ng-content>
