import {Pipe, PipeTransform} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable, switchMap, firstValueFrom} from 'rxjs';
import {amsPhotoUrl} from "../events";

@Pipe({
  standalone: true,
  name: 'authImage'
})
export class AuthImagePipe implements PipeTransform {

  constructor(
    private http: HttpClient
  ) {
  }

  getPhotoServerData(id: string, small: boolean): Observable<any> {
    const size = small ? '&size=900x510' : '&size=1600x1200';
    return amsPhotoUrl.pipe(
      switchMap((url: string) => this.http.get(url + '/image?imageId=' + id + size, {responseType: 'blob'}))
    ) as Observable<any>;
  }

  async transform(id: string, small: boolean): Promise<string> {
    try {
      const imageBlob = await firstValueFrom(this.getPhotoServerData(id, small));
      const reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.onloadend = () => resolve(reader.result as string);
        reader.readAsDataURL(imageBlob);
      });
    } catch {
      return 'assets/img/fallback.png';
    }
  }

}
