import {Component, OnDestroy, OnInit} from '@angular/core';
import {Plot, MonitorShortLabels} from "../../model/plot";
import {PlotsService} from "../../services/plots.service";
import {Observable, Subscription, firstValueFrom} from "rxjs";
import {LabeledFieldComponent} from "../labeled-field/labeled-field.component";
import {SortMonitorsPipe} from "../../pipes/sort-monitors.pipe";
import {FeatherModule} from "angular-feather";
import {CommonModule} from "@angular/common";
import {selectedPlotsChange} from '../../events';

@Component({
  standalone: true,
  selector: 'app-plot-details',
  templateUrl: './plot-details.component.html',
  imports: [
    CommonModule,
    LabeledFieldComponent,
    SortMonitorsPipe,
    FeatherModule
  ],
  styleUrls: ['./plot-details.component.scss']
})
export class PlotDetailsComponent implements OnInit, OnDestroy {

  plot$: Observable<Plot | null> | undefined;
  assessmentTags: any[] = [];

  subtables: any = {};

  subscription: Subscription | undefined;

  math = Math;

  finalResultsExpanded = true;
  resultsExpanded = true;

  expandedFinalResults: {monitor: string; updatedAt: number}[] = [];
  expandedResults: {monitor: string; updatedAt: number}[] = [];

  constructor(private plotsService: PlotsService) {
  }

  async ngOnInit() {
    this.assessmentTags = await firstValueFrom(this.plotsService.getAssessmentTags());
    this.subscription = selectedPlotsChange
      .subscribe(data => {
        if (data.plotIds.length >= 1) {
          this.plot$ = this.plotsService.getPlotDetails(data.plotIds[0]);
        } else {
          this.plot$ = undefined;
        }
      });
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  hasChildren(obj: any): boolean {
    return obj !== null && typeof obj === 'object' && Object.keys(obj).length > 0;
  }

  isArray(obj: any): boolean {
    return obj.hasOwnProperty('length');
  }

  getKeys(obj: any) {
    return Object.keys(obj);
  }

  expand(key: string) {
    this.subtables[key] = !this.subtables[key];
  }

  isExpanded(key: string): boolean {
    return !!this.subtables[key];
  }

  isFinalResultExpanded(monitor: string | undefined, updatedAt: Date | undefined) {
    const index = this.expandedFinalResults.findIndex(result => result.monitor === monitor && result.updatedAt === updatedAt?.valueOf());
    return updatedAt && index !== -1;
  }

  toggleExpandFinalResults() {
    this.finalResultsExpanded = !this.finalResultsExpanded;
  }

  toggleExpandResults() {
    this.resultsExpanded = !this.resultsExpanded;
  }

  toggleExpandFinalResult(monitor: string | undefined, updatedAt: Date | undefined) {
    if (updatedAt) {
      const index = this.expandedFinalResults.findIndex(result => result.monitor === monitor && result.updatedAt === updatedAt.valueOf());
      if (index !== -1) {
        this.expandedFinalResults.splice(index, 1);
      } else {
        this.expandedFinalResults.push({monitor: monitor || '', updatedAt: updatedAt.valueOf()});
      }
    }
  }

  isResultExpanded(monitor: string | undefined, updatedAt: Date | undefined) {
    const index = this.expandedResults.findIndex(result => result.monitor === monitor && result.updatedAt === updatedAt?.valueOf());
    return updatedAt && index !== -1;
  }

  toggleExpandResult(monitor: string | undefined, updatedAt: Date | undefined) {
    if (updatedAt) {
      const index = this.expandedResults.findIndex(result => result.monitor === monitor && result.updatedAt === updatedAt.valueOf());
      if (index !== -1) {
        this.expandedResults.splice(index, 1);
      } else {
        this.expandedResults.push({monitor: monitor || '', updatedAt: updatedAt.valueOf()});
      }
    }
  }

  getMonitorShortLabel(field: string | undefined) {
    if (field && ((MonitorShortLabels as any)[field])) {
      return (MonitorShortLabels as any)[field];
    } else {
      return field;
    }
  }

  protected readonly Math = Math;
}
